import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCryptoCopyButton',
    props: {
        isQRShown: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { $translate } = useI18n();
        const copyButtonProps = computed(()=>({
                label: $translate('WEB_2_CRYPTO_COPY_ADDRESS').value,
                fullWidth: true,
                disabled: props.loading || props.hasError
            }));
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LButton, _mergeProps({
                key: 1
            }, copyButtonProps.value, {
                kind: _unref(ButtonKind).PRIMARY,
                "icon-name": _unref(IconName).FILES,
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click'))
            }), null, 16, [
                "kind",
                "icon-name"
            ]));
    }
});
