import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { isOptionalString } from '@leon-hub/guards';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { SimpleRegCompletionRouteParametersHandler } from 'web/src/modules/core/services/router/navigation-guards/registration-completion/utils';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import useLoadingError from 'web/src/modules/payments/composables/useLoadingError';
import PaymentsDepositsNavigationParametersHolder from 'web/src/modules/core/composables/navigation/PaymentsDepositsNavigationParametersHolder';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
let PaymentsDepositsDetailsPrefetch = class PaymentsDepositsDetailsPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const depositStore = useDepositStore();
        const isAdditionalRegistrationNeeded = toRef(depositStore, 'isAdditionalRegistrationNeeded');
        const paymentSystemData = toRef(depositStore, 'paymentSystemData');
        const paymentSystemId = to.params.name;
        const { showLoadingError } = useLoadingError(router);
        isOptionalString(paymentSystemId);
        await depositStore.loadDepositSystemInfo(paymentSystemId).catch((error)=>showLoadingError(error));
        "1";
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (isAdditionalRegistrationNeeded.value) {
            SimpleRegCompletionRouteParametersHandler.setRedirectLocation(to);
            next({
                name: RouteName.REGISTRATION_COMPLETION,
                params: {
                    customerAction: CustomerCompletionAction.Deposit,
                    paymentSystemId: to.params.name
                }
            });
            return;
        }
        if (null === paymentSystemData.value) {
            next({
                name: RouteName.DEPOSITS,
                replace: !!PaymentsDepositsNavigationParametersHolder.getLastDepositedId()
            });
            return;
        }
        next();
    }
};
export { PaymentsDepositsDetailsPrefetch as default };
