import { IconName } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getCopyButtonProps($t, loading, hasError) {
    return {
        iconName: IconName.FILES,
        kind: ButtonKind.PRIMARY,
        label: $t('WEB_2_CRYPTO_COPY_ADDRESS').value,
        fullWidth: true,
        disabled: loading || hasError
    };
}
