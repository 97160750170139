import { useRouter } from 'vue-router';
import { computed } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { CustomerVerificationType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useRouterStore } from 'web/src/modules/core/store';
import { getCloseButtonProperties, getHelpButtonProperties, getIsAvailableVerification, getJumbotronProps, getListOfMethodsButtonProperties, getPaymentsLogoProperties, getStartButtonProperties } from 'web/src/modules/payments/components/PaymentsVerification/composables/utils';
import { useTheme } from 'web/src/modules/theme/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function usePaymentsVerification(props) {
    const { $translate } = useI18n();
    const { isDark } = useTheme();
    const router = useRouter();
    const customerStore = useCustomerDataStore();
    const paymentsLogoProperties = computed(()=>getPaymentsLogoProperties(props, isDark.value));
    const startButtonProperties = computed(()=>getStartButtonProperties($translate));
    const listOfMethodsButtonProperties = computed(()=>getListOfMethodsButtonProperties($translate));
    const helpButtonProperties = computed(()=>getHelpButtonProperties($translate));
    const closeButtonProperties = computed(()=>getCloseButtonProperties($translate));
    const isAvailableVerification = computed(()=>getIsAvailableVerification(props));
    const jumbotronProps = computed(()=>getJumbotronProps($translate));
    const pushToCustomerVerificationPage = ()=>{
        customerStore.setForcedVerificationType(CustomerVerificationType.CUSTOMER_VERIFICATION_SERVICE);
        router.push({
            name: RouteName.CUSTOMER_VERIFICATION
        });
    };
    const pushToSupportPage = ()=>{
        router.push({
            name: RouteName.SUPPORT
        });
    };
    const pushToMainPaymentPage = ()=>{
        if (useRouterStore().previousRouteName === RouteName.DEPOSITS) router.back();
        else router.replace({
            name: RouteName.DEPOSITS
        });
    };
    return {
        paymentsLogoProperties,
        startButtonProperties,
        listOfMethodsButtonProperties,
        helpButtonProperties,
        closeButtonProperties,
        isAvailableVerification,
        jumbotronProps,
        pushToCustomerVerificationPage,
        pushToSupportPage,
        pushToMainPaymentPage
    };
}
