import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PaymentsList from 'web/src/modules/payments/components/PaymentsList/PaymentsList.vue';
import useWithdrawalsRoutePage from 'web/src/modules/payments/submodules/withdrawals/composables/useWithdrawalsRoutePage';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsWithdrawalsRoutePage',
    setup (__props) {
        const PaymentsIdentificationStatus = // eslint-disable-next-line unicorn/prefer-module
        VEmpty;
        const { loading, withdrawalCategories, showIdentificationStatus, identificationStatusProperties, onIdentificationStatusButtonClick, hasErrorCode, hintErrorProperties, onErrorModalAction, titleErrorModal, errorMessage, isNoMethodMessageEnabled, hintBlockProperties, isShowFullNotification, onClickDeposit, onClickToggle, goToWithdrawalsDetailsRoute, goToDeposits } = useWithdrawalsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['payments-withdrawal']]: true,
                    [_ctx.$style['payments-withdrawal--loading']]: _unref(loading),
                    [_ctx.$style['payments-withdrawal--full-height']]: _unref(showIdentificationStatus)
                })
            }, [
                _unref(loading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : _unref(showIdentificationStatus) ? (_openBlock(), _createBlock(_unref(PaymentsIdentificationStatus), _mergeProps({
                    key: 1
                }, _unref(identificationStatusProperties), {
                    class: _ctx.$style['payments-withdrawal__identification-status'],
                    onClick: _unref(onIdentificationStatusButtonClick)
                }), null, 16, [
                    "class",
                    "onClick"
                ])) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _unref(hasErrorCode) ? (_openBlock(), _createBlock(VHintBlock, _mergeProps({
                        key: 0
                    }, _unref(hintErrorProperties), {
                        class: _ctx.$style['payments-withdrawal__notification'],
                        onOnAccept: _unref(onErrorModalAction)
                    }), {
                        title: _withCtx(()=>[
                                _createElementVNode("span", null, _toDisplayString(_unref(titleErrorModal)), 1)
                            ]),
                        default: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['payments-withdrawal__notification-text'])
                                }, _toDisplayString(_unref(errorMessage) ?? ''), 3)
                            ]),
                        _: 1
                    }, 16, [
                        "class",
                        "onOnAccept"
                    ])) : _createCommentVNode("", true),
                    //@ts-ignore
                    _createCommentVNode("", true),
                    _unref(withdrawalCategories).length ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['payments-withdrawal__inner'])
                    }, [
                        _createVNode(PaymentsList, {
                            categories: _unref(withdrawalCategories),
                            "enrollment-time-in-hint": "",
                            "is-withdrawals": "",
                            onClick: _unref(goToWithdrawalsDetailsRoute)
                        }, null, 8, [
                            "categories",
                            "onClick"
                        ]),
                        (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['payments-withdrawal__deposits-button']),
                            onClick: _cache[1] || (_cache[1] = //@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(goToDeposits) && _unref(goToDeposits)(...args);
                            })
                        }, _toDisplayString(_ctx.$t('WEB2_REFILL')), 3))
                    ], 2)) : (_openBlock(), _createBlock(VJumbotron, {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['payments-deposits__no-payment']),
                        text: _unref(errorMessage) || (_unref(isNoMethodMessageEnabled) && _unref(withdrawalCategories).length ? '' : _ctx.$t('WEB2_NO_PAYMENTS_SYSTEMS_ERROR'))
                    }, null, 8, [
                        "class",
                        "text"
                    ]))
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsWithdrawalsRoutePage'
                ]
            ]);
        };
    }
});
