import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import TextInput from 'web/src/components/Input/components/TextInput/TextInput.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCopyCryptoAddress',
    props: {
        label: {},
        address: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "copy-crypto",
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
            }, [
                _createVNode(TextInput, {
                    "suffix-icon-name": _unref(IconName).FILES,
                    label: props.label,
                    value: props.address,
                    readonly: ""
                }, null, 8, [
                    "suffix-icon-name",
                    "label",
                    "value"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsCopyCryptoAddress'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'copy-crypto__text'
                    }
                ]
            ]);
        };
    }
});
