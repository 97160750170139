import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { CustomerConfig, CustomerVerificationStatus, PaymentType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import useBonusStore from 'web/src/modules/bonuses/store/useBonusStore';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
import usePaymentsIdentification from 'web/src/modules/payments/composables/usePaymentsIdentification';
import useRouterStore from 'web/src/modules/core/store/useRouterStore';
import { mapFormData } from 'web/src/components/Form/utils';
import { AnalyticsModalType, DialogAction, DialogComponent, DialogPriority, PresetName } from 'web/src/modules/dialogs/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import getSubmitPaymentPayload from 'web/src/modules/payments/utils/getSubmitPaymentPayload';
import { PaymentFormFieldName, PaymentsFormStep } from 'web/src/modules/payments/enums';
import { PAYMENTS_DEFAULT_INPUT_VALUE } from 'web/src/modules/payments/constants';
import useDepositsNavigation from 'web/src/modules/payments/submodules/deposits/composables/useDepositsNavigation';
import { getImageOrIcon } from 'web/src/modules/icons';
// eslint-disable-next-line max-len
export function useDepositDetailsRoutePage() {
    const loading = ref(true);
    const router = useRouter();
    const route = useRoute();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const formatMoney = useFormatMoney();
    const eventBus = useEventsBus();
    const showPaymentsModalControllerData = ref(false);
    const paymentStore = usePaymentsStore();
    const depositStore = useDepositStore();
    const routerStore = useRouterStore();
    const bonusStore = useBonusStore();
    const customerDataStore = useCustomerDataStore();
    const userStore = useUserStore();
    const analytics = useAnalytics();
    const { onBeforeDetailsPageMounted } = usePaymentsIdentification(PaymentType.DEPOSIT);
    const { setPreviousPageUrl } = useDepositsNavigation();
    const mobileRedirectUrl = toRef(paymentStore, 'mobileRedirectUrlFormatted');
    const withdrawalStatsData = toRef(paymentStore, 'withdrawalStatsData');
    const redirectUrl = toRef(depositStore, 'redirectUrl');
    const htmlContent = toRef(depositStore, 'htmlContent');
    const htmlContentProperties = toRef(depositStore, 'htmlContentProperties');
    const redirectCharset = toRef(depositStore, 'redirectCharset');
    const redirectFormParams = toRef(depositStore, 'redirectFormParams');
    const redirectMethod = toRef(depositStore, 'redirectMethod');
    const paymentSystemData = toRef(depositStore, 'paymentSystemData');
    const paymentSystemId = toRef(depositStore, 'paymentSystemId');
    const paymentMessage = toRef(depositStore, 'paymentMessage');
    const formStep = toRef(depositStore, 'formStep');
    const formProps = toRef(depositStore, 'formProps');
    const submitButton = toRef(depositStore, 'submitButton');
    const creditCardToken = toRef(depositStore, 'creditCardToken');
    const initialModalProperties = toRef(depositStore, 'initialModalProperties');
    const cardExpirationDate = toRef(depositStore, 'cardExpirationDate');
    const amount = toRef(depositStore, 'amount');
    const amountAsNumber = toRef(depositStore, 'amountAsNumber');
    const amountAsMoney = toRef(depositStore, 'amountAsMoney');
    const submittedAmountAsMoney = toRef(depositStore, 'submittedAmountAsMoney');
    const minAmount = toRef(depositStore, 'minAmount');
    const hasDepositBonus = toRef(depositStore, 'hasDepostBonus');
    const isFormPending = toRef(depositStore, 'isFormPending');
    const customErrors = toRef(depositStore, 'customErrors');
    const showDepositBonusIntro = toRef(depositStore, 'showDepositBonusIntro');
    const selectedBonusMinAmount = toRef(depositStore, 'selectedBonusMinAmount');
    const selectedBonus = toRef(depositStore, 'currentBonus');
    const previousRouteName = toRef(routerStore, 'previousRouteName');
    const verificationStatus = toRef(customerDataStore, 'verificationStatus');
    const phoneDecodedString = toRef(customerDataStore, 'phoneDecodedString');
    const { isPaymentsMinAmountSuggestionEnabled } = usePaymentsConfig();
    function initSafari() {
        if (process.env.VUE_APP_BROWSER_SAFARI) window.addEventListener('pageshow', (event)=>{
            if (event.persisted) {
                "1";
                router.replace({
                    name: RouteName.DEPOSITS_DETAILS,
                    params: {
                        name: paymentSystemId.value
                    }
                });
            }
        });
        else if (paymentSystemId.value) router.replace({
            name: RouteName.DEPOSITS_DETAILS,
            params: {
                name: paymentSystemId.value
            }
        });
    }
    const beforeMount = async ()=>{
        bonusStore.setChoosingBonusBeforeDeposit(false);
        paymentStore.loadWithdrawalStats();
        initSafari();
        await onBeforeDetailsPageMounted();
        loading.value = false;
    };
    const mounted = async ()=>{
        setPreviousPageUrl();
        await bonusStore.getPaymentBonuses();
        analytics.push(AnalyticsEvent.Z_OPEN_DEPOSIT_METHOD, {
            deposits: {
                openDepositMethod: paymentSystemId.value
            }
        });
    };
    onBeforeMount(beforeMount);
    onMounted(mounted);
    onBeforeUnmount(()=>{
        if (redirectUrl.value || htmlContent.value || formStep.value !== PaymentsFormStep.INITIAL) depositStore.clearSubmitResponse();
    });
    function onChange(data) {
        const inputedAmount = data.formData[PaymentFormFieldName.AMOUNT];
        if (inputedAmount) amount.value = inputedAmount;
        if (data.field === PaymentFormFieldName.CUST_BCODES) amount.value = selectedBonusMinAmount.value > amountAsNumber.value ? `${selectedBonusMinAmount.value}` : amount.value;
    }
    function changeLogoIfNeeded(value) {
        const fieldOptions = formProps.value?.uiSchema?.fields?.phone?.options;
        const hasLogoProps = fieldOptions && 'logotypedCountryCode' in fieldOptions;
        if (hasLogoProps && '255' === fieldOptions.logotypedCountryCode) {
            if (value.suffix && value.suffix?.length >= 2) // eslint-disable-next-line default-case
            switch(value.suffix.substring(0, 2)){
                case '62':
                    depositStore.changePhoneLogo(require('web/src/assets/images/sunny/tanzania/halo.svg'));
                    return;
                case '65':
                case '67':
                case '71':
                case '77':
                    depositStore.changePhoneLogo(require('web/src/assets/images/sunny/tanzania/tigo.svg'));
                    return;
                case '68':
                case '69':
                case '78':
                    depositStore.changePhoneLogo(require('web/src/assets/images/sunny/tanzania/airtel.svg'));
                    return;
                case '74':
                case '75':
                case '76':
                    depositStore.changePhoneLogo(require('web/src/assets/images/sunny/tanzania/m-pesa.svg'));
                    return;
            }
            depositStore.changePhoneLogo('');
        }
    }
    function onInput(data) {
        const inputedAmount = data.formData[PaymentFormFieldName.AMOUNT];
        const inputedCardNumberToken = data.formData[PaymentFormFieldName.CARDNUMBER];
        const inputedCardExpirationDate = data.formData[PaymentFormFieldName.EXPIRATION];
        const inputedPhone = data.formData[PaymentFormFieldName.PHONE];
        if (inputedAmount || data.field === PaymentFormFieldName.AMOUNT && !inputedAmount) amount.value = inputedAmount ?? PAYMENTS_DEFAULT_INPUT_VALUE;
        if (inputedCardNumberToken) creditCardToken.value = inputedCardNumberToken;
        if (inputedCardExpirationDate) cardExpirationDate.value = inputedCardExpirationDate;
        if (inputedPhone) changeLogoIfNeeded(inputedPhone);
    }
    const showPaymentsModal = computed(()=>showPaymentsModalControllerData.value && !htmlContent.value);
    const selectedBonusAmount = computed(()=>({
            amount: formatMoney(selectedBonusMinAmount.value, {
                currency: selectedBonus.value?.minDepositAmount?.currency
            })
        }));
    const selectedBonusConfirmMessage = $translate('WEB2_DEPOSIT_BONUS_MODAL_MIN_AMOUNT_MESSAGE', selectedBonusAmount);
    const VERIFICATION_STATUSES = [
        CustomerVerificationStatus.IN_PROCESS,
        CustomerVerificationStatus.SUCCESS
    ];
    const showVerification = computed(()=>{
        if (!paymentSystemData.value?.forcedCustomerVerificationRequired) return false;
        return !(verificationStatus.value && VERIFICATION_STATUSES.includes(verificationStatus.value));
    });
    // eslint-disable-next-line max-len
    async function validateDepositBonusIntro(data) {
        return new Promise((resolve)=>{
            if (showDepositBonusIntro.value && amountAsNumber.value > 0 && !data.formData[PaymentFormFieldName.CUST_BCODES]) {
                showDialog({
                    presetName: PresetName.CONFIRM,
                    options: {
                        image: getImageOrIcon({
                            alertIcon: AlertIconName.QuestionMark
                        }).image,
                        priority: DialogPriority.notification,
                        confirmMessage: $translate('WEB2_DEPOSIT_BONUS_CONFIRM_MESSAGE').value,
                        title: $translate('WEB2_DEPOSIT_BONUS_CONFIRM_TITLE').value,
                        iconName: IconName.PROMOS_NO,
                        buttons: [
                            {
                                label: `${$translate('WEB2_REPLENISH').value} ${amountAsMoney.value}`,
                                iconName: IconName.SHIELD,
                                iconSize: IconSize.SIZE_16
                            },
                            {
                                action: DialogAction.ADDITIONAL_BUTTON,
                                label: $translate('WEB2_SELECT_BONUS').value
                            }
                        ]
                    }
                }).subscribe({
                    [DialogAction.CONFIRM]: ()=>{
                        resolve(true);
                    },
                    [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                        eventBus.emit(BusEvent.DEPOSIT_BONUS_WIDGET_OPEN_LIST, {});
                        resolve(false);
                    },
                    [DialogAction.MODAL_CLOSE]: ()=>{
                        resolve(false);
                    }
                });
                userStore.setAdditionalPropsUser({
                    value: {
                        value: true
                    },
                    config: CustomerConfig.HIDE_NO_SELECTED_BONUS_MODAL
                });
                depositStore.setSelectedBonusModalStatus(true);
            } else resolve(true);
        });
    }
    // eslint-disable-next-line max-len
    async function validateDepositBonusMinAmount(data) {
        return new Promise((resolve)=>{
            // eslint-disable-next-line max-len
            if (selectedBonusMinAmount.value > amountAsNumber.value && void 0 !== data.formData[PaymentFormFieldName.AMOUNT]) showDialog({
                presetName: PresetName.CONFIRM,
                options: {
                    image: getImageOrIcon({
                        alertIcon: AlertIconName.QuestionMark
                    }).image,
                    iconName: IconName.PROMOS_NO,
                    confirmMessage: selectedBonusConfirmMessage.value,
                    title: $translate('WEB2_DEPOSIT_BONUS_CONFIRM_TITLE').value,
                    buttons: [
                        {
                            label: `${$translate('WEB2_REPLENISH').value} ${amountAsMoney.value}`,
                            iconName: IconName.SHIELD,
                            iconSize: IconSize.SIZE_16
                        },
                        {
                            action: DialogAction.ADDITIONAL_BUTTON,
                            label: $translate('WEB2_DEPOSIT_BONUS_MODAL_CHANGE_SUMMA').value
                        }
                    ]
                }
            }).subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    resolve(true);
                },
                [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                    amount.value = `${selectedBonusMinAmount.value}`;
                    resolve(false);
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    resolve(false);
                }
            });
            else resolve(true);
        });
    }
    // eslint-disable-next-line max-len
    async function validateDepositBonus(data) {
        const isValidIntro = await validateDepositBonusIntro(data);
        const isValidMinAmount = await validateDepositBonusMinAmount(data);
        return isValidIntro && isValidMinAmount;
    }
    function showDepositSuccessModalForMobilePlatforms() {
        const { subscribe } = showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                modalComponent: DialogComponent.ConfirmDepositModal,
                fullHeight: true,
                confirmMessage: phoneDecodedString.value,
                title: $translate('WEB2_INVOICE_ISSUED').value,
                buttons: [
                    {
                        kind: ButtonKind.BASE
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                depositStore.pushOnSuccess();
            }
        });
    }
    function showDepositSuccessModalForOtherPlatforms() {
        showPaymentsModalControllerData.value = true;
    }
    function onPaymentsModalControllerClose() {
        showPaymentsModalControllerData.value = false;
        depositStore.pushOnSuccess();
    }
    function paymentErrorDepositLimitReachedShowModal(error) {
        const { subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                confirmMessage: error?.message,
                title: $translate('WEB2_OPERATION_FAILED').value,
                analyticsModalType: AnalyticsModalType.WARNING,
                buttons: [
                    {
                        label: $translate('WEB2_CHANGE_DEPOSIT_LIMIT_LINK').value
                    },
                    {
                        label: $translate('JSP_PCL_FBOT_CLOSE').value
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                router.push({
                    name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS
                });
            }
        });
    }
    function paymentErrorDepositProcessingErrorModal(error) {
        showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                confirmMessage: error?.message,
                title: $translate('WEB2_OPERATION_FAILED').value,
                analyticsModalType: AnalyticsModalType.WARNING,
                buttons: [
                    {
                        label: $translate('WEB2_HELP_BUTTON_IN_MODAL').value
                    },
                    {
                        label: $translate('JSP_PCL_FBOT_CLOSE').value
                    }
                ]
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                router.push({
                    name: RouteName.SUPPORT
                });
            }
        });
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity,max-len
    async function onSubmit(data, action) {
        if (hasDepositBonus.value) {
            const isDepositBonusValid = await validateDepositBonus(data);
            if (!isDepositBonusValid) return;
        }
        if (formStep.value === PaymentsFormStep.INITIAL) analytics.push(AnalyticsEvent.Z_CLICK_DEPOSIT, {
            deposits: {
                clickDeposit: paymentSystemData.value?.id
            }
        });
        depositStore.submitDeposit(getSubmitPaymentPayload({
            paymentSystemId: paymentSystemId.value || '',
            schemaId: paymentSystemData.value?.schemaId || '',
            formParams: mapFormData(data.formData),
            action
        })).then(()=>{
            if (paymentSystemData.value?.isMobileCommerce && paymentMessage.value) showDepositSuccessModalForMobilePlatforms();
            else if (paymentMessage.value) showDepositSuccessModalForOtherPlatforms();
        }).catch((error)=>{
            depositStore.handlePaymentError(error, ()=>{
                if (error.code.equals('DEPOSIT_LIMIT_REACHED')) {
                    paymentErrorDepositLimitReachedShowModal(error);
                    return true;
                }
                if (error.code.equals('DEPOSIT_PROCESSING_ERROR')) {
                    paymentErrorDepositProcessingErrorModal(error);
                    return true;
                }
                return false;
            });
        });
    }
    const allowedFromProps = computed(()=>({
            value: formatMoney(minAmount.value || 0)
        }));
    const allowedFromMessage = $translate('WEB2_ALLOWED_FROM', allowedFromProps);
    function onDepositDisableClick(data) {
        if (amount.value === data.formData.amount && isPaymentsMinAmountSuggestionEnabled.value && minAmount.value && amountAsNumber.value < minAmount.value) {
            analytics.push(AnalyticsEvent.Z_CLICK_NON_ACTIVE_DEPOSIT_BUTTON, {
                deposits: {
                    clickNonActiveDeposit: paymentSystemId.value
                }
            });
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    priority: DialogPriority.default,
                    confirmMessage: allowedFromMessage.value,
                    title: $translate('WEB2_CHANGE_DEPOSIT').value,
                    buttons: [
                        {
                            label: $translate('WEB2_CHANGE').value
                        }
                    ]
                }
            });
        }
    }
    watch(()=>route, (newValue, oldValue)=>{
        if (newValue.name === oldValue.name && newValue.name === RouteName.DEPOSITS_DETAILS) {
            beforeMount();
            mounted();
        }
    });
    return {
        loading,
        redirectUrl,
        htmlContent,
        htmlContentProperties,
        redirectCharset,
        paymentSystem: paymentSystemData,
        showVerification,
        verificationStatus,
        paymentMessage,
        formStep,
        formProps,
        customErrors,
        initialModalProperties,
        previousRouteName,
        hasDepositBonus,
        redirectFormParams,
        redirectMethod,
        mobileRedirectUrl,
        showPaymentsModal,
        amountAsMoney,
        isFormPending,
        paymentSystemId,
        onSubmit,
        onInput,
        onChange,
        onPaymentsModalControllerClose,
        onDepositDisableClick,
        withdrawalStatsData,
        submittedAmountAsMoney,
        submitButton
    };
}
