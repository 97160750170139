import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { PaymentType } from '@leon-hub/api-sdk';
import { VLoaderDelayed } from 'web/src/components/Loader';
import useWithdrawalsDetailsRoutePage from 'web/src/modules/payments/submodules/withdrawals/composables/useWithdrawalsDetailsRoutePage';
import PaymentSystem from 'web/src/modules/payments/components/PaymentSystem/PaymentSystem.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import PaymentsWithdrawalRestrictions from 'web/src/modules/payments/submodules/withdrawals/components/PaymentsWithdrawalRestrictions/PaymentsWithdrawalRestrictions.vue';
import PaymentsCryptoCurrencySelect from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/PaymentsCryptoCurrencySelect.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsWithdrawalsDetailsRoutePage',
    setup (__props) {
        const { loading, customErrors, paymentSystem, formProps, formStep, paymentMessage, initialModalProperties, redirectUrl, htmlContent, htmlContentProperties, previousRouteName, redirectCharset, redirectFormParams, redirectMethod, mobileRedirectUrl, amountAsMoney, companyFeeHintDescription, paymentSystemId, showModalRestriction, remainingStake, availableWithdrawal, isFormPending, onSubmit, onInput, onBackToGame } = useWithdrawalsDetailsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['payments-withdrawals-details-page']]: true,
                    [_ctx.$style['payments-withdrawals-details-page--extra-height']]: _unref(showModalRestriction) && void 0,
                    [_ctx.$style['payments-withdrawals-details-page--loading']]: _unref(loading)
                })
            }, [
                _unref(loading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : _unref(paymentSystem) && _unref(formProps)?.uiSchema && _unref(formProps)?.schema ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(paymentSystem).isCrypto ? (_openBlock(), _createBlock(PaymentsCryptoCurrencySelect, {
                        key: 0,
                        type: _unref(PaymentType).WITHDRAWAL,
                        "payment-system": _unref(paymentSystem),
                        class: _normalizeClass(_ctx.$style['payments-withdrawals-details-page__row'])
                    }, null, 8, [
                        "type",
                        "payment-system",
                        "class"
                    ])) : _createCommentVNode("", true),
                    _createVNode(PaymentSystem, {
                        "payment-system": _unref(paymentSystem),
                        "ui-form-schema": _unref(formProps).uiSchema,
                        "form-step": _unref(formStep),
                        "payment-error": _unref(customErrors),
                        "payment-message": _unref(paymentMessage),
                        "initial-modal-properties": _unref(initialModalProperties),
                        schema: _unref(formProps).schema,
                        "redirect-url": _unref(redirectUrl),
                        "html-content": _unref(htmlContent),
                        "html-content-properties": _unref(htmlContentProperties),
                        "previous-route-name": _unref(previousRouteName),
                        "redirect-charset": _unref(redirectCharset),
                        "redirect-form-params": _unref(redirectFormParams),
                        "redirect-method": _unref(redirectMethod),
                        "mobile-redirect-url": _unref(mobileRedirectUrl),
                        "amount-as-money": _unref(amountAsMoney),
                        "company-fee-hint-description": _unref(companyFeeHintDescription),
                        "is-form-pending": _unref(isFormPending),
                        onSubmit: _unref(onSubmit),
                        onInput: _unref(onInput)
                    }, null, 8, [
                        "payment-system",
                        "ui-form-schema",
                        "form-step",
                        "payment-error",
                        "payment-message",
                        "initial-modal-properties",
                        "schema",
                        "redirect-url",
                        "html-content",
                        "html-content-properties",
                        "previous-route-name",
                        "redirect-charset",
                        "redirect-form-params",
                        "redirect-method",
                        "mobile-redirect-url",
                        "amount-as-money",
                        "company-fee-hint-description",
                        "is-form-pending",
                        "onSubmit",
                        "onInput"
                    ])
                ], 64)) : _createCommentVNode("", true),
                _createVNode(ModalPortal, {
                    selector: _unref(ModalSelector).DESKTOP_INNER_MODAL
                }, {
                    default: _withCtx(()=>[
                            _unref(showModalRestriction) ? (_openBlock(), _createBlock(PaymentsWithdrawalRestrictions, {
                                key: 0,
                                "remaining-stake": _unref(remainingStake),
                                "available-withdrawal": _unref(availableWithdrawal),
                                onClose: _cache[0] || (_cache[0] = ($event)=>showModalRestriction.value = false),
                                onButtonClick: _unref(onBackToGame)
                            }, null, 8, [
                                "remaining-stake",
                                "available-withdrawal",
                                "onButtonClick"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "selector"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsWithdrawalsDetailsRoutePage'
                ],
                [
                    _directive_data_test,
                    {
                        el: _unref(paymentSystemId)
                    }
                ]
            ]);
        };
    }
});
