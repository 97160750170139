import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { IconName } from '@leon-hub/icons';
import { isInFrame } from '@leon-hub/html-utils';
import PaymentsDepositsOkFailController from 'web/src/modules/payments/components/PaymentsDepositsOkFailController/PaymentsDepositsOkFailController.vue';
import { PaymentsDepositStatus } from 'web/src/modules/payments/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsProcessRoutePage',
    props: {
        closeWindow: {
            type: Boolean
        }
    },
    setup (__props) {
        const margin = isInFrame();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(PaymentsDepositsOkFailController, {
                title: _ctx.$t('WEB2_PAYMENT_IN_PROGRESS'),
                message: _ctx.$t('WEB2_BALANCE_WILL_BE_UPDATED'),
                status: _unref(PaymentsDepositStatus).PROCESS,
                "icon-name": _unref(IconName).CHECK_OUTLINE,
                margin: _unref(margin),
                "close-window": !!_ctx.closeWindow,
                class: _normalizeClass(_ctx.$style['payments-deposits-ok-process'])
            }, null, 8, [
                "title",
                "message",
                "status",
                "icon-name",
                "margin",
                "close-window",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsProcessRoutePage'
                ]
            ]);
        };
    }
});
