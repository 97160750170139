import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VEmpty } from 'web/src/components/Empty';
import usePaymentsCryptoCurrencySelect from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/composables/usePaymentsCryptoCurrencySelect';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCryptoCurrencySelect',
    props: {
        type: {},
        paymentSystem: {}
    },
    emits: [
        "on-select"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const DropListSelect = VEmpty;
        const { selectedCurrency, currencyDropdownOptions, selectPaymentMethod } = usePaymentsCryptoCurrencySelect(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['payments-crypto-currency-select'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['payments-crypto-currency-select__row'])
                }, [
                    _createVNode(_unref(DropListSelect), {
                        selected: _unref(selectedCurrency),
                        options: _unref(currencyDropdownOptions),
                        label: _ctx.$t('WEB_2_CRYPTO_NETWORK_CURRENCY'),
                        "show-selected-icon": "",
                        "disable-hover-filter": "",
                        "search-enabled": "",
                        onItemClick: _unref(selectPaymentMethod)
                    }, null, 8, [
                        "selected",
                        "options",
                        "label",
                        "onItemClick"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsCryptoCurrencySelect'
                ]
            ]);
        };
    }
});
