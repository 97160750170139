import { computed, onBeforeMount, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { PaymentType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
export default function usePaymentsCryptoCurrencySelect(props, emit) {
    const router = useRouter();
    const depositStore = useDepositStore();
    const withdrawalStore = useWithdrawalStore();
    const depositCategories = toRef(depositStore, 'depositCategories');
    const withdrawalCategories = toRef(withdrawalStore, 'withdrawalCategories');
    const selectedCurrency = computed(()=>props.paymentSystem?.paymentSystemId ?? void 0);
    const list = computed(()=>{
        if (props.type === PaymentType.DEPOSIT) return depositCategories.value.find((category)=>category.isFolded) ?? null;
        if (props.type === PaymentType.WITHDRAWAL) return withdrawalCategories.value.find((category)=>category.isFolded) ?? null;
        return null;
    });
    const currencyDropdownOptions = computed(()=>list.value?.paymentSystems?.filter((payment)=>!payment.disabled).map((payment)=>({
                value: payment.id || '',
                label: payment.name || '',
                imageSrc: payment.logoSrc || void 0
            })) ?? []);
    const selectPaymentMethod = (id)=>{
        const system = list.value?.paymentSystems.find((item)=>item.id === id);
        if (system) {
            router.replace({
                name: props.type === PaymentType.DEPOSIT ? RouteName.DEPOSITS_DETAILS : RouteName.WITHDRAWALS_DETAILS,
                params: {
                    name: system.id,
                    systemName: system.name
                },
                query: {
                    campaignId: router.getQuery('campaignId'),
                    sum: router.getQuery('sum')
                }
            });
            emit('on-select', system.id);
        }
    };
    onBeforeMount(async ()=>{
        // eslint-disable-next-line default-case
        switch(props.type){
            case PaymentType.DEPOSIT:
                if (!depositCategories.value.length) await depositStore.loadDepositSystems();
                break;
            case PaymentType.WITHDRAWAL:
                if (!withdrawalCategories.value.length) await withdrawalStore.loadWithdrawalSystems();
                break;
        }
    });
    return {
        selectedCurrency,
        currencyDropdownOptions,
        selectPaymentMethod
    };
}
