import { IconName } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getStartButtonProperties($t) {
    return {
        iconName: IconName.SHIELD,
        kind: ButtonKind.PRIMARY,
        label: $t('WEB2_PAYMENTS_SUMSUB_BUTTON_START').value,
        fullWidth: true
    };
}
