import { computed, nextTick, onBeforeMount, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { TargetParamWithdrawal, Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config-names';
import { CustomerConfig, PaymentType, CustomerVerificationType } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import useBonusStore from 'web/src/modules/bonuses/store/useBonusStore';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import usePaymentsIdentification from 'web/src/modules/payments/composables/usePaymentsIdentification';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useLoadingError from 'web/src/modules/payments/composables/useLoadingError';
import { ErrorCodeDeposit } from 'web/src/modules/payments/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import usePaymentsDepositsNavigation from 'web/src/modules/core/composables/navigation/usePaymentsDepositsNavigation';
import useDepositsNavigation from 'web/src/modules/payments/submodules/deposits/composables/useDepositsNavigation';
import PaymentsDepositsNavigationParametersHolder from 'web/src/modules/core/composables/navigation/PaymentsDepositsNavigationParametersHolder';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { getImageOrIcon } from 'web/src/modules/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useUserStore } from 'web/src/modules/user/store';
import usePaymentsBonuses from 'web/src/modules/payments/composables/usePaymentsBonuses';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useDepositsRoutePage() {
    const depositStore = useDepositStore();
    const customeConfigStore = useCustomerDataStore();
    const router = useRouter();
    const bonusStore = useBonusStore();
    const userStore = useUserStore();
    const eventBus = useEventsBus();
    const paymentsBonuses = usePaymentsBonuses();
    const paymentBonuses = toRef(()=>bonusStore.paymentBonuses);
    const { showIdentificationStatus, identificationStatusProperties, onIdentificationStatusButtonClick, waitForGettingIdentificationState, finishCustomerIdentification } = usePaymentsIdentification(PaymentType.DEPOSIT);
    const { showDialog } = useDialogs();
    const navigation = usePaymentsDepositsNavigation();
    const siteConfigStore = useSiteConfigStore();
    const analitycs = useAnalytics();
    const { showLoadingError } = useLoadingError(router);
    const { $translate } = useI18n();
    const { setPreviousPageUrl } = useDepositsNavigation();
    const depositCategories = toRef(()=>depositStore.depositCategories);
    const errorCode = toRef(()=>depositStore.errorCode);
    const errorMessage = toRef(()=>depositStore.errorMessage);
    const isBonusListBeforeDepositEnabled = toRef(()=>siteConfigStore.isBonusListBeforeDepositEnabled);
    const isBonusChooserShownBeforeDeposits = toRef(()=>bonusStore.isBonusChooserShownBeforeDeposits);
    const depositBonusList = toRef(()=>paymentsBonuses.depositBonusList.value);
    const isEkycRequestable = toRef(()=>customeConfigStore.isEkycRequestable ?? false);
    const customerConfig = toRef(()=>customeConfigStore.customerConfig);
    const isEkycVerificationShown = ref(false);
    const loading = ref(true);
    const lastDepositedId = ref(PaymentsDepositsNavigationParametersHolder.getLastDepositedId());
    onBeforeMount(async ()=>{
        if (depositCategories.value.length) loading.value = false;
        await depositStore.loadDepositSystems().catch((error)=>showLoadingError(error));
        setPreviousPageUrl();
        await waitForGettingIdentificationState();
        if (lastDepositedId.value) {
            PaymentsDepositsNavigationParametersHolder.setLastDepositedId(void 0);
            try {
                await router.push({
                    name: RouteName.DEPOSITS_DETAILS,
                    params: {
                        name: lastDepositedId.value
                    }
                });
            } finally{
                await nextTick();
                await nextAnimationFrame();
                lastDepositedId.value = void 0;
            }
        }
        loading.value = false;
        if (isEkycRequestable.value) isEkycVerificationShown.value = true;
        if (!customerConfig.value?.[CustomerConfig.IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN] && isEkycRequestable.value) {
            userStore.setAdditionalPropsUser({
                config: CustomerConfig.IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN,
                value: {
                    value: true
                }
            });
            showDialog({
                presetName: PresetName.CONFIRM,
                options: {
                    title: $translate('JSPACC_VERIFY_VERIFICATION').value,
                    image: getImageOrIcon({
                        image: {
                            src: require('web/src/modules/payments/submodules/deposits/assets/aadhaar.svg')
                        }
                    }).image,
                    confirmMessage: $translate('WEB2_MORE_PAYMENTS').value,
                    buttons: [
                        {
                            iconName: IconName.CHECK_FILLED,
                            label: $translate('WEB2_VERIFY').value,
                            kind: ButtonKind.PRIMARY,
                            action: DialogAction.CONFIRM
                        },
                        {
                            label: $translate('WEB2_BUTTON_SKIP').value,
                            kind: ButtonKind.NAV,
                            action: DialogAction.MODAL_CLOSE
                        }
                    ],
                    dataTestId: 'aadhaar-verify'
                }
            }).subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    showDialog({
                        presetName: PresetName.CONFIRM,
                        options: {
                            title: $translate('JSPACC_VERIFY_VERIFICATION').value,
                            iconName: IconName.VERIFICATION,
                            confirmMessage: $translate('WEB2_VERIFICATION_MESSAGE').value,
                            buttons: [
                                {
                                    label: $translate('WEB2_CONTINUE').value,
                                    kind: ButtonKind.PRIMARY,
                                    action: DialogAction.CONFIRM
                                },
                                {
                                    label: $translate('WEB2_MODAL_CANCEL').value,
                                    kind: ButtonKind.NAV,
                                    action: DialogAction.MODAL_CLOSE
                                }
                            ],
                            dataTestId: 'aadhaar-verify-step-2'
                        }
                    }).subscribe({
                        [DialogAction.CONFIRM]: ()=>{
                            verifyEkyc();
                        }
                    });
                }
            });
        }
    });
    onMounted(async ()=>{
        await finishCustomerIdentification();
        const hasLast = router.getQuery('last');
        if (void 0 !== hasLast) navigation.pushToDepositsPage({
            needToReplace: true
        });
        await bonusStore.getPaymentBonuses();
    });
    const isPaymentBonuses = toRef(()=>paymentBonuses.value.length > 0 && isBonusListBeforeDepositEnabled.value);
    const isPaymentBonusesBeforeDeposit = toRef(()=>!isBonusChooserShownBeforeDeposits.value && isPaymentBonuses.value);
    const titleErrorModal = toRef(()=>{
        // in case new statuses arise, as in payments
        // eslint-disable-next-line sonarjs/no-small-switch
        switch(errorCode.value){
            case ErrorCodeDeposit.EKYC_NEEDED:
                return $translate('WEB2_EKYC_NEEDED_TITLE').value;
            default:
                return '';
        }
    });
    function verifyEkyc() {
        customeConfigStore.setForcedVerificationType(CustomerVerificationType.CUSTOMER_VERIFICATION_SERVICE);
        router.push({
            name: RouteName.CUSTOMER_VERIFICATION
        });
    }
    const errorModalButtonLabel = $translate('WEB2_NOTIFICATION_VERIFICATION_BUTTON');
    const hasErrorCode = toRef(()=>!!errorCode.value && !!errorMessage.value);
    const routeNameErrorModal = computed(()=>{
        // in case new statuses arise, as in payments
        // eslint-disable-next-line sonarjs/no-small-switch
        switch(errorCode.value){
            case ErrorCodeDeposit.EKYC_NEEDED:
                return RouteName.CUSTOMER_VERIFICATION;
            default:
                return '';
        }
    });
    const hintErrorProperties = toRef(()=>({
            icon: IconName.ATTENTION_OUTLINE,
            labelAccept: errorModalButtonLabel.value
        }));
    const sHintErrorProperties = toRef(()=>({
            primaryButton: {
                kind: 'primary',
                size: 'small',
                label: errorModalButtonLabel.value
            },
            type: 'quaternary'
        }));
    const bonusMinDepositAmount = computed(()=>{
        const campaignId = router.getQuery('campaignId');
        if (campaignId) {
            const bonus = depositBonusList.value.find((depositBonus)=>String(depositBonus.campaignId) === String(campaignId));
            if (bonus?.minDepositAmount) return String(bonus.minDepositAmount.amount);
        }
        return null;
    });
    function goToWithdrawals() {
        analitycs.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                withdrawal: TargetParamWithdrawal.DEPOSIT_LINK
            }
        });
        router.push({
            name: RouteName.WITHDRAWALS
        });
        eventBus.emit(BusEvent.MODAL_CONTENT_SCROLL_TOP, {});
    }
    async function goToDepositsDetailsRoute(system) {
        if (system?.id && !system?.disabled) {
            depositStore.setLastVisitedDepositSystemId(system.id);
            const query = {
                campaignId: router.getQuery('campaignId')
            };
            const sum = router.getQuery('sum') || bonusMinDepositAmount.value;
            if (sum) query.sum = sum;
            await router.push({
                name: RouteName.DEPOSITS_DETAILS,
                params: {
                    name: system.id
                },
                query
            });
        }
    }
    function onErrorModalAction() {
        if (!routeNameErrorModal.value) return;
        router.push({
            name: routeNameErrorModal.value
        });
    }
    return {
        lastDepositedId,
        loading,
        showIdentificationStatus: computed(()=>showIdentificationStatus.value && !loading.value),
        isPaymentBonuses,
        isPaymentBonusesBeforeDeposit,
        goToWithdrawals,
        goToDepositsDetailsRoute,
        depositCategories,
        paymentBonuses,
        identificationStatusProperties,
        onIdentificationStatusButtonClick,
        hasErrorCode,
        hintErrorProperties,
        sHintErrorProperties,
        onErrorModalAction,
        titleErrorModal,
        errorMessage,
        isEkycVerificationShown,
        verifyEkyc
    };
}
