import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { IconName } from '@leon-hub/icons';
import PaymentsDepositsOkFailController from 'web/src/modules/payments/components/PaymentsDepositsOkFailController/PaymentsDepositsOkFailController.vue';
import { PaymentsDepositStatus } from 'web/src/modules/payments/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsfailRoutePage',
    props: {
        margin: {
            type: Boolean
        },
        closeWindow: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(PaymentsDepositsOkFailController, {
                title: _ctx.$t('WEB2_DEPFAIL_TITLE'),
                message: _ctx.$t('WEB2_DEPFAIL_MESSAGE'),
                "icon-name": _unref(IconName).ATTENTION_OUTLINE,
                status: _unref(PaymentsDepositStatus).FAIL,
                margin: !!_ctx.margin,
                "close-window": !!_ctx.closeWindow
            }, null, 8, [
                "title",
                "message",
                "icon-name",
                "status",
                "margin",
                "close-window"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsfailRoutePage'
                ]
            ]);
        };
    }
});
