import { computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { HistoryFilter } from '@leon-hub/api-sdk';
import { AlertIconName } from '@leon-hub/icons';
import { localStorageManager } from '@leon-hub/local-storage';
import RouteName from '@leon-hub/routing-config-names';
import { isInFrame } from '@leon-hub/html-utils';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useFormatMoney } from 'web/src/modules/money/composables';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import PaymentsWithdrawalsType, { PaymentsNDFLType } from 'web/src/modules/payments/types/PaymentsWithdrawalsTypes';
export default function usePaymentsWithdrawalsokRoutePage(props) {
    const formatMoney = useFormatMoney();
    const withdrawalStore = useWithdrawalStore();
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    const router = useRouter();
    const showDefaultDialog = computed(()=>true);
    const closeWindow = computed(()=>void 0 !== props.closeWindow);
    const margin = computed(()=>isInFrame());
    function showWithdrawalSuccessModal() {
        const nettoAmount = localStorageManager.getItem(PaymentsNDFLType.NETTO_AMOUNT) || '0';
        const { subscribe } = showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                modalComponent: DialogComponent.ConfirmWithdrawalModal,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.Success
                }).image,
                fullHeight: true,
                amount: formatMoney(parseInt(nettoAmount, 10)),
                type: PaymentsWithdrawalsType.INCOME_TAX,
                title: $translate('WEB2_WITHDRAWAL_REQUEST_SUBMITTED').value,
                buttons: [
                    {
                        kind: ButtonKind.BASE
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                pushOnSuccess();
            },
            [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                router.replace({
                    name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
                    query: {
                        filter: HistoryFilter.ALL_WITHDRAWALS
                    }
                });
            }
        });
    }
    function pushOnSuccess() {
        router.closeModal();
    }
    function onCreated() {
        if (!showDefaultDialog.value) showWithdrawalSuccessModal();
    }
    function beforeUnmount() {
        if (!showDefaultDialog.value) withdrawalStore.clearNDFLLocalStorageData();
    }
    onBeforeMount(onCreated);
    onBeforeUnmount(beforeUnmount);
    return {
        margin,
        closeWindow,
        showDefaultDialog
    };
}
