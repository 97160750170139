import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VLoaderDelayed } from 'web/src/components/Loader';
import PaymentsVerification from 'web/src/modules/payments/components/PaymentsVerification/PaymentsVerification.vue';
import PaymentsCrypto from 'web/src/modules/payments/components/PaymentsCrypto/PaymentsCrypto.vue';
import { useDepositDetailsRoutePage } from 'web/src/modules/payments/submodules/deposits/composables/useDepositDetailsRoutePage';
import PaymentSystem from 'web/src/modules/payments/components/PaymentSystem/PaymentSystem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsDetailsRoutePage',
    setup (__props) {
        const { loading, redirectUrl, htmlContent, htmlContentProperties, paymentSystem, showVerification, verificationStatus, paymentMessage, formStep, formProps, customErrors, initialModalProperties, previousRouteName, redirectCharset, redirectFormParams, redirectMethod, mobileRedirectUrl, showPaymentsModal, amountAsMoney, isFormPending, paymentSystemId, onSubmit, onInput, onChange, onPaymentsModalControllerClose, onDepositDisableClick, withdrawalStatsData, submittedAmountAsMoney, submitButton } = useDepositDetailsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['payments-deposits-details-page']]: true,
                    [_ctx.$style['payments-deposits-details-page--loading']]: _unref(loading),
                    [_ctx.$style['payments-deposits-details-page--has-redirect']]: !!_unref(redirectUrl)
                })
            }, [
                _unref(loading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : _unref(showVerification) && _unref(paymentSystem) ? (_openBlock(), _createBlock(PaymentsVerification, {
                    key: 1,
                    "payment-system": _unref(paymentSystem),
                    "verification-status": _unref(verificationStatus)
                }, null, 8, [
                    "payment-system",
                    "verification-status"
                ])) : _unref(paymentSystem)?.isCrypto ? (_openBlock(), _createBlock(PaymentsCrypto, {
                    key: 2,
                    "payment-system": _unref(paymentSystem)
                }, null, 8, [
                    "payment-system"
                ])) : _unref(paymentSystem) ? (_openBlock(), _createBlock(PaymentSystem, {
                    key: 3,
                    "payment-system": _unref(paymentSystem),
                    "ui-form-schema": _unref(formProps)?.uiSchema,
                    "form-step": _unref(formStep),
                    "payment-error": _unref(customErrors),
                    "payment-message": _unref(paymentMessage),
                    "submit-button": _unref(submitButton),
                    "initial-modal-properties": _unref(initialModalProperties),
                    schema: _unref(formProps)?.schema,
                    "redirect-url": _unref(redirectUrl),
                    "html-content": _unref(htmlContent),
                    "html-content-properties": _unref(htmlContentProperties),
                    "previous-route-name": _unref(previousRouteName),
                    "redirect-charset": _unref(redirectCharset),
                    "redirect-form-params": _unref(redirectFormParams),
                    "redirect-method": _unref(redirectMethod),
                    "mobile-redirect-url": _unref(mobileRedirectUrl),
                    "show-payments-modal": _unref(showPaymentsModal),
                    "amount-as-money": _unref(amountAsMoney),
                    "is-form-pending": _unref(isFormPending),
                    "withdrawal-stats-data": _unref(withdrawalStatsData),
                    "modal-text": _unref(submittedAmountAsMoney),
                    onSubmit: _unref(onSubmit),
                    onChange: _unref(onChange),
                    onInput: _unref(onInput),
                    onClose: _unref(onPaymentsModalControllerClose),
                    onDisabledClick: _unref(onDepositDisableClick)
                }, null, 8, [
                    "payment-system",
                    "ui-form-schema",
                    "form-step",
                    "payment-error",
                    "payment-message",
                    "submit-button",
                    "initial-modal-properties",
                    "schema",
                    "redirect-url",
                    "html-content",
                    "html-content-properties",
                    "previous-route-name",
                    "redirect-charset",
                    "redirect-form-params",
                    "redirect-method",
                    "mobile-redirect-url",
                    "show-payments-modal",
                    "amount-as-money",
                    "is-form-pending",
                    "withdrawal-stats-data",
                    "modal-text",
                    "onSubmit",
                    "onChange",
                    "onInput",
                    "onClose",
                    "onDisabledClick"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsDetailsRoutePage'
                ],
                [
                    _directive_data_test,
                    {
                        el: _unref(paymentSystemId)
                    }
                ]
            ]);
        };
    }
});
