import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import { CustomerVerificationStatus, CustomerVerificationType } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
export default function useAdditionalVerificationModal() {
    const router = useRouter();
    const customerDataStore = useCustomerDataStore();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const verificationStatus = toRef(customerDataStore, 'verificationStatus');
    const verificationType = toRef(customerDataStore, 'verificationType');
    const isVerificationPurseCheck = toRef(customerDataStore, 'isVerificationPurseCheck');
    const verificationModalServiceTitle = computed(()=>{
        switch(verificationStatus.value){
            case CustomerVerificationStatus.STARTED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_STARTED_TITLE').value : $translate('WEB2_PAYMENTS_VERIFY_STARTED_TITLE').value;
            case CustomerVerificationStatus.IN_PROCESS:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_IN_PROCESS_TITLE').value : $translate('WEB2_PAYMENTS_VERIFY_IN_PROCESS_TITLE').value;
            case CustomerVerificationStatus.RETRY:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_RETRY_TITLE').value : $translate('WEB2_PAYMENTS_VERIFY_RETRY_TITLE').value;
            case CustomerVerificationStatus.FAILED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_FAILED_TITLE').value : $translate('WEB2_PAYMENTS_VERIFY_FAILED_TITLE').value;
            default:
                return '';
        }
    });
    const verificationModalTitle = computed(()=>{
        if (verificationType.value === CustomerVerificationType.INTERNAL) return $translate('WEB2_VERIFY_YOUR_IDENTITY').value;
        return verificationModalServiceTitle.value;
    });
    const verificationModalServiceDescription = computed(()=>{
        switch(verificationStatus.value){
            case CustomerVerificationStatus.STARTED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_STARTED_DESC').value : $translate('WEB2_PAYMENTS_VERIFY_STARTED_DESC').value;
            case CustomerVerificationStatus.IN_PROCESS:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_IN_PROCESS_DESC').value : $translate('WEB2_PAYMENTS_VERIFY_IN_PROCESS_DESC').value;
            case CustomerVerificationStatus.RETRY:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_RETRY_DESC').value : $translate('WEB2_PAYMENTS_VERIFY_RETRY_DESC').value;
            case CustomerVerificationStatus.FAILED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_FAILED_DESC').value : $translate('WEB2_PAYMENTS_VERIFY_FAILED_DESC').value;
            default:
                return '';
        }
    });
    const verificationModalDescription = computed(()=>{
        if (verificationType.value === CustomerVerificationType.INTERNAL) return $translate('WEB2_DOCUMENTS_VERIFICATION').value;
        return verificationModalServiceDescription.value;
    });
    const verificationModalServiceButtonLabel = computed(()=>{
        switch(verificationStatus.value){
            case CustomerVerificationStatus.STARTED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_STARTED_BTN').value : $translate('WEB2_PAYMENTS_VERIFY_STARTED_BTN').value;
            case CustomerVerificationStatus.IN_PROCESS:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_IN_PROCESS_BTN').value : $translate('WEB2_PAYMENTS_VERIFY_IN_PROCESS_BTN').value;
            case CustomerVerificationStatus.RETRY:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_RETRY_BTN').value : $translate('WEB2_PAYMENTS_VERIFY_RETRY_BTN').value;
            case CustomerVerificationStatus.FAILED:
                return isVerificationPurseCheck.value ? $translate('WEB2_PAYMENTS_PURSE_CHECK_FAILED_BTN').value : $translate('WEB2_PAYMENTS_VERIFY_FAILED_BTN').value;
            default:
                return '';
        }
    });
    const verificationModalButtonLabel = computed(()=>{
        if (verificationType.value === CustomerVerificationType.INTERNAL) return $translate('WEB2_UPLOAD_DOCUMENTS').value;
        return verificationModalServiceButtonLabel.value;
    });
    const verificationModalIcon = computed(()=>verificationStatus.value === CustomerVerificationStatus.IN_PROCESS || verificationStatus.value === CustomerVerificationStatus.RETRY ? IconName.CLOCK : IconName.ATTENTION_OUTLINE);
    function openAdditionVerificationNeededModal() {
        showDialog({
            presetName: PresetName.ALERT_ERROR,
            options: {
                title: verificationModalTitle.value,
                confirmMessage: verificationModalDescription.value,
                iconName: verificationModalIcon.value,
                buttons: [
                    {
                        action: DialogAction.ADDITIONAL_BUTTON,
                        label: verificationModalButtonLabel.value
                    }
                ],
                dataTestId: 'additional-verification-needed'
            }
        }).subscribe({
            [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                router.push({
                    name: verificationStatus.value === CustomerVerificationStatus.FAILED ? RouteName.SUPPORT : RouteName.CUSTOMER_VERIFICATION
                });
            }
        });
    }
    return {
        openAdditionVerificationNeededModal
    };
}
