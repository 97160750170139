import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "verification"
};
const _hoisted_2 = {
    class: "verification__description"
};
const _hoisted_3 = {
    class: "verification__buttons"
};
const _hoisted_4 = {
    class: "verification"
};
const _hoisted_5 = {
    class: "verification__description"
};
const _hoisted_6 = {
    class: "verification__buttons"
};
import PaymentsLogo from 'web/src/modules/payments/components/PaymentsLogo/PaymentsLogo.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { usePaymentsVerification } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsVerification',
    props: {
        paymentSystem: {},
        verificationStatus: {}
    },
    setup (__props) {
        const props = __props;
        const { paymentsLogoProperties, startButtonProperties, listOfMethodsButtonProperties, helpButtonProperties, closeButtonProperties, isAvailableVerification, jumbotronProps, pushToCustomerVerificationPage, pushToSupportPage, pushToMainPaymentPage } = usePaymentsVerification(props);
        return (_ctx, _cache)=>_unref(isAvailableVerification) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(PaymentsLogo, _mergeProps(_unref(paymentsLogoProperties), {
                    class: "verification__logo"
                }), null, 16),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('WEB2_SOFORT_DEPOSIT_VERIF_NEEDED')), 1),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(VButton, _mergeProps(_unref(startButtonProperties), {
                        onClick: _unref(pushToCustomerVerificationPage)
                    }), null, 16, [
                        "onClick"
                    ]),
                    _createVNode(VButton, _mergeProps(_unref(listOfMethodsButtonProperties), {
                        onClick: _unref(pushToMainPaymentPage)
                    }), null, 16, [
                        "onClick"
                    ])
                ])
            ])) : (_openBlock(), _createBlock(_unref(VJumbotron), _normalizeProps(_mergeProps({
                key: 1
            }, _unref(jumbotronProps))), {
                default: _withCtx(()=>[
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('WEB2_SOFORT_DEPOSIT_VERIF_FAILED')), 1),
                            _createElementVNode("div", _hoisted_6, [
                                _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(helpButtonProperties), {
                                    onClick: _unref(pushToSupportPage)
                                }), null, 16, [
                                    "onClick"
                                ]),
                                _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(closeButtonProperties), {
                                    onClick: _unref(pushToMainPaymentPage)
                                }), null, 16, [
                                    "onClick"
                                ])
                            ])
                        ])
                    ]),
                _: 1
            }, 16));
    }
});
