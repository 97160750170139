import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { PaymentType } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { PaymentsPage } from 'web/src/modules/core/services/router/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { PaymentsFormStep } from 'web/src/modules/payments/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { useBalance } from 'web/src/modules/customer/store/composables';
import { useRouterStore } from 'web/src/modules/core/store';
import { useCustomThemeColor } from 'web/src/modules/theme/composables';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function usePaymentsTopBar(props) {
    const isBackButtonInTopBar = toRef(useRouterStore(), 'isBackButtonInTopBar');
    const { $translate } = useI18n();
    const router = useRouter();
    const analytics = useAnalytics();
    const customerDataStore = useCustomerDataStore();
    const formatMoney = useFormatMoney();
    const { currency } = useCurrency();
    const { balance } = useBalance();
    const paymentStore = computed(()=>props.paymentType === PaymentType.DEPOSIT ? useDepositStore() : useWithdrawalStore());
    const paymentSystemId = computed(()=>paymentStore.value.paymentSystemId);
    const formStep = computed(()=>paymentStore.value.formStep);
    const name = computed(()=>paymentStore.value.name);
    const nextSteps = computed(()=>paymentStore.value.nextSteps);
    computed(()=>!!paymentStore.value.paymentSystemData?.isCrypto);
    const htmlContent = computed(()=>!!paymentStore.value.htmlContent);
    const lastDepositedSystemId = toRef(customerDataStore, 'lastDepositedSystemId');
    computed(()=>!!paymentStore.value.paymentSystemData?.isTerminal);
    const title = computed(()=>{
        switch(formStep.value){
            case PaymentsFormStep.NEW_CARD:
                return $translate('WEB2_CARD_DETAILS').value;
            case PaymentsFormStep.CVC:
                return $translate('WEB2_ENTER_CVV_CODE').value;
            default:
                break;
        }
        switch(props.page){
            case PaymentsPage.MOBILE_DEPOSITS:
            case PaymentsPage.DESKTOP_DEPOSITS:
                return $translate('WEB2_DEPOSIT_REFILL').value;
            case PaymentsPage.DESKTOP_WITHDRAWALS:
            case PaymentsPage.MOBILE_WITHDRAWALS:
                return $translate('WEB2_BANK_TRANSFER').value;
            case PaymentsPage.DESKTOP_BONUS_EXCHANGE_DETAILS:
                return $translate('JSP_ENCASH_MOBILE_TITLE').value;
            case PaymentsPage.DEPOSIT_DETAILS:
            case PaymentsPage.WITHDRAWAL_DETAILS:
                return name.value || '&nbsp;';
            default:
                break;
        }
        return '&nbsp;';
    });
    const isDepositDetailsMoreIcon = computed(()=>lastDepositedSystemId.value === paymentSystemId.value && props.page === PaymentsPage.DEPOSIT_DETAILS && formStep.value === PaymentsFormStep.INITIAL);
    const prefixIcon = computed(()=>{
        if (isDepositDetailsMoreIcon.value) return IconName.MORE;
        if (formStep.value && formStep.value !== PaymentsFormStep.INITIAL) return IconName.ARROW_LEFT;
    });
    const pushBackPageName = computed(()=>{
        switch(props.page){
            case PaymentsPage.DEPOSIT_DETAILS:
            case PaymentsPage.DESKTOP_DEPOSITS:
            case PaymentsPage.MOBILE_DEPOSITS:
                return RouteName.DEPOSITS;
            case PaymentsPage.DESKTOP_WITHDRAWALS:
            case PaymentsPage.MOBILE_WITHDRAWALS:
            case PaymentsPage.WITHDRAWAL_DETAILS:
                return RouteName.WITHDRAWALS;
            default:
                return RouteName.HOME;
        }
    });
    const depositPages = [
        PaymentsPage.DEPOSIT_DETAILS
    ];
    [
        ...depositPages,
        PaymentsPage.WITHDRAWAL_DETAILS
    ];
    const formattedBalance = computed(()=>formatMoney(balance.value, currency.value ? {
            currency: currency.value
        } : {}));
    const balanceTitle = computed(()=>$translate('WEB2_PAYMENTS_BALANCE', ref({
            total: formattedBalance
        })).value);
    computed(()=>{
        const isLight = false;
        const isNotIncludedDepositState = props.page === PaymentsPage.DEPOSIT_DETAILS && !!htmlContent.value;
        return isLight && !isNotIncludedDepositState;
    });
    const showBalance = computed(()=>false);
    const headerType = computed(()=>{});
    function pushBack() {
        if (isDepositDetailsMoreIcon.value && !isBackButtonInTopBar.value) {
            router.replace({
                name: pushBackPageName.value
            });
            return;
        }
        if (router.getName() !== pushBackPageName.value && formStep.value !== PaymentsFormStep.INITIAL) router.replace({
            name: pushBackPageName.value
        });
        else router.back();
    }
    function sendAnalyticsOnBackArrowClick() {
        const methodName = props.paymentType === PaymentType.DEPOSIT ? 'depositMethod' : 'withdrawalMethod';
        if (methodName) analytics.clickMap({
            [methodName]: 'backToList'
        });
    }
    function onBack() {
        if (formStep.value === PaymentsFormStep.INITIAL) sendAnalyticsOnBackArrowClick();
        if (null !== nextSteps.value && formStep.value !== PaymentsFormStep.INITIAL) paymentStore.value.clearCurrentStep();
        else pushBack();
    }
    function onClose() {
        router.closeModal();
    }
    useCustomThemeColor(headerType);
    return {
        title,
        prefixIcon,
        balanceTitle,
        headerType,
        showBalance,
        onBack,
        onClose
    };
}
