import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerConfig, HistoryFilter, PaymentType, PinCodePlace, PinCodeRequiredExceptionCode, PinCodeState as PinCodeStateEnum, RemoteApiErrorExceptionCode } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { localStorageManager } from '@leon-hub/local-storage';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { normalizeError } from '@leon-hub/errors';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { isNumber } from '@leon-hub/guards';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { getImageOrIcon } from 'web/src/modules/icons';
import usePaymentsIdentification from 'web/src/modules/payments/composables/usePaymentsIdentification';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
import { useSiteConfigStore, useDesktopModalStore } from 'web/src/modules/core/store';
import { IS_PIN_CODE_MODAL_CREATE_AVAILABLE } from 'web/src/modules/pin-code/store/usePinCodeStore';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, DialogComponent, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useRouterStore from 'web/src/modules/core/store/useRouterStore';
import PaymentsWithdrawalsType from 'web/src/modules/payments/types/PaymentsWithdrawalsTypes';
import getSubmitPaymentPayload from 'web/src/modules/payments/utils/getSubmitPaymentPayload';
import { mapFormData } from 'web/src/components/Form/utils';
import { PaymentFormFieldName, PaymentsFormStep } from 'web/src/modules/payments/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
// eslint-disable-next-line max-len
export default function useWithdrawalsDetailsRoutePage() {
    const router = useRouter();
    const { showDialog, closeDialog } = useDialogs();
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const { convertToBaseError } = useErrorsConverter();
    const analytics = useAnalytics();
    const loading = ref(true);
    const showModalRestriction = ref(false);
    const remainingStake = ref('');
    const availableWithdrawal = ref('');
    const numberOfWithdrawalAmountExceededAttempts = ref(0);
    const paymentStore = usePaymentsStore();
    const withdrawalStore = useWithdrawalStore();
    const pinCodeStore = usePinCodeStore();
    const routerStore = useRouterStore();
    const siteConfigStore = useSiteConfigStore();
    const userStore = useUserStore();
    const desktopModal = toRef(useDesktopModalStore(), 'desktopModal');
    const submitData = toRef(withdrawalStore, 'submitData');
    const paymentSystemData = toRef(withdrawalStore, 'paymentSystemData');
    const formProps = toRef(withdrawalStore, 'formProps');
    const formStep = toRef(withdrawalStore, 'formStep');
    const paymentMessage = toRef(withdrawalStore, 'paymentMessage');
    const paymentSystemId = toRef(withdrawalStore, 'paymentSystemId');
    const initialModalProperties = toRef(withdrawalStore, 'initialModalProperties');
    const redirectUrl = toRef(withdrawalStore, 'redirectUrl');
    const htmlContent = toRef(withdrawalStore, 'htmlContent');
    const htmlContentProperties = toRef(withdrawalStore, 'htmlContentProperties');
    const redirectCharset = toRef(withdrawalStore, 'redirectCharset');
    const redirectFormParams = toRef(withdrawalStore, 'redirectFormParams');
    const redirectMethod = toRef(withdrawalStore, 'redirectMethod');
    const amount = toRef(withdrawalStore, 'amount');
    const amountAsMoney = toRef(withdrawalStore, 'amountAsMoney');
    const amountAsNumber = toRef(withdrawalStore, 'amountAsNumber');
    const companyFee = toRef(withdrawalStore, 'companyFee');
    const hideCommissionHint = toRef(withdrawalStore, 'hideCommissionHint');
    const nettoAmount = toRef(withdrawalStore, 'nettoAmount');
    const taxAmount = toRef(withdrawalStore, 'taxAmount');
    const customErrors = toRef(withdrawalStore, 'customErrors');
    const creditCardToken = toRef(withdrawalStore, 'creditCardToken');
    const isFormPending = toRef(withdrawalStore, 'isFormPending');
    const balanceAfterWithdrawal = toRef(withdrawalStore, 'balanceAfterWithdrawal');
    const previousRouteName = toRef(routerStore, 'previousRouteName');
    const mobileRedirectUrl = toRef(paymentStore, 'mobileRedirectUrlFormatted');
    const pinCodeModalShowLimit = toRef(siteConfigStore, 'pinCodeModalShowLimit');
    const pinCodeRequestedPlaces = toRef(siteConfigStore, 'pinCodeRequestedPlaces');
    const pinCodeModalCounter = toRef(pinCodeStore, 'pinCodeModalCounter');
    const isProfile = computed(()=>!!desktopModal.value?.isProfile);
    const { submitWithdrawal, getCompanyFee, pushOnSuccess, clearNDFLLocalStorageData, setSubmitData, handlePaymentError } = withdrawalStore;
    const { onBeforeDetailsPageMounted } = usePaymentsIdentification(PaymentType.WITHDRAWAL);
    onBeforeMount(async ()=>{
        await onBeforeDetailsPageMounted();
        if (submitData.value) submit(submitData.value);
        else loading.value = false;
    });
    onBeforeUnmount(()=>{
        if (redirectUrl.value || htmlContent.value || formStep.value !== PaymentsFormStep.INITIAL) withdrawalStore.clearSubmitResponse(false);
    });
    function pinCodeCreateModal() {
        showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                ...getImageOrIcon({
                    icon: IconName.SHIELD_LOCK,
                    alertIcon: AlertIconName.Lock
                }),
                width: ModalWidth.SMALL,
                title: $translate('WEB2_SET_PIN_CODE_TITLE').value,
                confirmMessage: $translate('WEB2_SET_PIN_CODE_DESCRIPTION').value,
                buttons: [
                    {
                        label: $translate('WEB2_SET_PIN_CODE').value
                    },
                    {
                        label: pinCodeModalCounter.value === pinCodeModalShowLimit.value - 1 ? $translate('WEB2_DONT_SHOW_AGAIN').value : $translate('WEB2_SET_PIN_CODE_LATER').value
                    }
                ]
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                pinCodeStore.setRouteFrom({
                    name: RouteName.WITHDRAWALS_DETAILS,
                    params: {
                        name: submitData.value?.payload.paymentSystemId
                    }
                });
                pinCodeStore.setStepWithLS('CREATE');
                router.replace({
                    name: RouteName.PIN_CODE_PROFILE
                });
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                localStorageManager.setItem(IS_PIN_CODE_MODAL_CREATE_AVAILABLE, '0');
                if (submitData.value) submit(submitData.value);
            }
        });
    }
    function showWithdrawalSuccessModal() {
        userStore.loadUserBonusData();
        const { subscribe } = showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                modalComponent: DialogComponent.ConfirmWithdrawalModal,
                ...getImageOrIcon({
                    alertIcon: AlertIconName.Success
                }),
                fullHeight: true,
                amount: formatMoney(taxAmount.value && nettoAmount.value ? nettoAmount.value : balanceAfterWithdrawal.value || 0),
                type: taxAmount.value ? PaymentsWithdrawalsType.INCOME_TAX : PaymentsWithdrawalsType.DEFAULT,
                title: $translate('WEB2_WITHDRAWAL_REQUEST_SUBMITTED').value,
                buttons: [
                    {
                        kind: ButtonKind.BASE
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                pushOnSuccess();
                clearNDFLLocalStorageData();
            },
            [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                router.push({
                    name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
                    query: {
                        filter: HistoryFilter.ALL_WITHDRAWALS
                    }
                });
            }
        });
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function submit(data) {
        if (isFormPending.value) return;
        submitWithdrawal(data).then(()=>{
            if (!isProfile.value) {
                router.replace({
                    name: RouteName.WITHDRAWALS_DETAILS,
                    params: {
                        name: data.payload.paymentSystemId
                    },
                    query: {
                        successMessage: 'show'
                    }
                });
                return;
            }
            if (paymentMessage.value) showWithdrawalSuccessModal();
            sendAnalyticsOnAmountExceedNoErrors();
            setSubmitData(null);
        }).catch((rawError)=>{
            if (rawError instanceof GqlApiBatchedSubRequestError && rawError.code.equals(RemoteApiErrorExceptionCode.WITHDRAWAL_AMOUNT_EXCEEDED)) {
                const { remainingStakeAmount, availableForWithdraw } = rawError.extensions;
                if (isNumber(remainingStakeAmount) && isNumber(availableForWithdraw)) {
                    remainingStake.value = formatMoney(remainingStakeAmount);
                    availableWithdrawal.value = formatMoney(availableForWithdraw);
                    showModalRestriction.value = true;
                    numberOfWithdrawalAmountExceededAttempts.value += 1;
                    sendAnaluticsOnAmountExceedError();
                }
                setSubmitData(null);
                return;
            }
            const error = convertToBaseError(rawError);
            if (error.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
                setSubmitData(data);
                pinCodeStore.setRouteFrom({
                    name: RouteName.WITHDRAWALS_DETAILS,
                    params: {
                        name: data?.payload.paymentSystemId
                    }
                });
                router.replace({
                    name: RouteName.PIN_CODE_PROFILE
                });
            } else {
                handlePaymentError(rawError);
                setSubmitData(null);
            }
        }).finally(()=>{
            loading.value = false;
        });
    }
    function sendAnalyticsOnAmountExceedNoErrors() {
        if (!numberOfWithdrawalAmountExceededAttempts.value) analytics.push(AnalyticsEvent.Z_WITHDRAWAL_WAGER_NO, {
            withdrawals: {
                modalWindowWager: 'no'
            }
        });
    }
    function sendAnaluticsOnAmountExceedError() {
        analytics.push(AnalyticsEvent.Z_WITHDRAWAL_WAGER_YES, {
            withdrawals: {
                modalWindowWager: numberOfWithdrawalAmountExceededAttempts.value
            }
        });
    }
    const hasPinCodeShowLimit = computed(()=>pinCodeModalCounter.value < pinCodeModalShowLimit.value);
    const hasAvailablePinCodePlaces = computed(()=>pinCodeRequestedPlaces.value?.includes(PinCodePlace.WITHDRAWAL));
    const companyFeeProps = computed(()=>{
        if (paymentSystemData.value) {
            const { companyFeePercent, companyFeeThreshold } = paymentSystemData.value;
            return {
                companyFeePercent,
                companyFeeThreshold
            };
        }
        return null;
    });
    function handlePinCode(data) {
        if (hasPinCodeShowLimit.value && hasAvailablePinCodePlaces.value) pinCodeStore.refreshPinCodeStatus().then((result)=>{
            if (result && result !== PinCodeStateEnum.RESET) submit(data);
            else {
                const isModalCreateAvailable = localStorageManager.getItem(IS_PIN_CODE_MODAL_CREATE_AVAILABLE);
                if (Number(isModalCreateAvailable)) {
                    setSubmitData(data);
                    pinCodeCreateModal();
                } else submit(data);
            }
        });
        else submit(data);
    }
    const companyFeeThresholdAsMoney = computed(()=>formatMoney(companyFeeProps.value?.companyFeeThreshold || 0));
    const confirmMessageProps = computed(()=>({
            companyFeeThreshold: companyFeeThresholdAsMoney.value,
            companyFeePercent: `${companyFeeProps.value?.companyFeePercent || ''}`
        }));
    const confirmMessage = $translate('WEB2_WITHDRAWAL_COMPANY_FEE_DESCRIPTION', confirmMessageProps);
    // eslint-disable-next-line max-len
    async function showCompanyFeeConfirmModal(data) {
        try {
            if (companyFeeProps.value?.companyFeePercent !== null && companyFeeProps.value?.companyFeeThreshold !== null && paymentSystemId.value) await getCompanyFee({
                amount: amountAsNumber.value,
                paymentSystemId: paymentSystemId.value
            });
            const payableAmount = companyFee.value?.payableAmount || amountAsNumber.value;
            if (!hideCommissionHint.value && payableAmount < amountAsNumber.value) {
                const { subscribe, id } = showDialog({
                    presetName: PresetName.CONFIRM,
                    options: {
                        isCloseAllowed: true,
                        iconName: void 0,
                        fullHeight: false,
                        width: ModalWidth.SMALL,
                        title: $translate('WEB2_WITHDRAWAL_COMPANY_FEE_TITLE').value,
                        confirmMessage: confirmMessage.value,
                        buttons: [
                            {
                                label: `${$translate('WEB2_WITHDRAW').value} ${formatMoney(payableAmount)}`,
                                iconName: IconName.SHIELD
                            },
                            {
                                label: $translate('WEB2_DONT_SHOW_AGAIN').value,
                                action: DialogAction.SECONDARY_CLICK
                            }
                        ]
                    }
                });
                subscribe({
                    [DialogAction.CONFIRM]: ()=>{
                        closeDialog(id);
                        handlePinCode(data);
                    },
                    [DialogAction.SECONDARY_CLICK]: ()=>{
                        closeDialog(id);
                        userStore.setAdditionalPropsUser({
                            value: {
                                value: true
                            },
                            config: CustomerConfig.HIDE_COMMISSION_HINT
                        });
                        hideCommissionHint.value = true;
                        handlePinCode(data);
                    },
                    [DialogAction.MODAL_CLOSE]: ()=>{
                        companyFee.value = null;
                        closeDialog(id);
                    }
                });
            } else handlePinCode(data);
        } catch (rawError) {
            companyFee.value = null;
            const error = normalizeError(rawError);
            showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    title: $translate('WEB2_MODALTITLE_ATTENTION').value,
                    confirmMessage: error.message,
                    fullHeight: false,
                    buttons: [
                        {
                            kind: ButtonKind.PRIMARY,
                            action: DialogAction.MODAL_CLOSE,
                            label: $translate('JSP_PCL_FBOT_CLOSE').value
                        }
                    ]
                }
            });
        }
    }
    // eslint-disable-next-line max-len
    async function onSubmit(data, action) {
        const payload = getSubmitPaymentPayload({
            paymentSystemId: paymentSystemData.value?.paymentSystemId || '',
            schemaId: paymentSystemData.value?.schemaId || '',
            formParams: mapFormData(data.formData),
            action
        });
        if (formStep.value === PaymentsFormStep.INITIAL) await showCompanyFeeConfirmModal(payload);
        else handlePinCode(payload);
    }
    function onInput(data) {
        const inputedAmount = data.formData[PaymentFormFieldName.AMOUNT];
        const inputedCardNumberToken = data.formData[PaymentFormFieldName.CARDNUMBER_WITHDRAWAL] ?? data.formData[PaymentFormFieldName.CARDNUMBER];
        const cardHolder = data.formData[PaymentFormFieldName.CARDHOLDER];
        if (inputedAmount) amount.value = inputedAmount;
        if (inputedCardNumberToken) creditCardToken.value = inputedCardNumberToken;
        withdrawalStore.setCardHolder(cardHolder ?? '');
    }
    const descriptionProps = computed(()=>({
            companyFeeThreshold: formatMoney(paymentSystemData.value?.companyFeeThreshold ?? 0),
            companyFeePercent: `${paymentSystemData.value?.companyFeePercent ?? ''}`
        }));
    const companyFeeHintDescription = computed(()=>{
        if (descriptionProps.value.companyFeePercent) return $translate('WEB2_WITHDRAWAL_COMPANY_FEE_DESCRIPTION', descriptionProps).value;
        return '';
    });
    function onBackToGame(action) {
        if (action.action === DialogAction.MODAL_CLOSE) {
            showModalRestriction.value = false;
            return;
        }
        router.push({
            name: RouteName.HOME
        });
        showModalRestriction.value = false;
    }
    function checkSuccessMessageVisibility() {
        if ('show' === router.getQuery('successMessage')) {
            router.replace({
                name: RouteName.WITHDRAWALS_DETAILS,
                params: {
                    name: router.getParam('name')
                }
            });
            if (paymentMessage.value) showWithdrawalSuccessModal();
            sendAnalyticsOnAmountExceedNoErrors();
            setSubmitData(null);
        }
    }
    onMounted(()=>{
        checkSuccessMessageVisibility();
    });
    return {
        loading,
        customErrors,
        paymentSystem: paymentSystemData,
        formProps,
        formStep,
        paymentMessage,
        initialModalProperties,
        redirectUrl,
        htmlContent,
        htmlContentProperties,
        previousRouteName,
        redirectCharset,
        redirectFormParams,
        redirectMethod,
        mobileRedirectUrl,
        amountAsMoney,
        companyFeeHintDescription,
        isFormPending,
        paymentSystemId,
        showModalRestriction,
        remainingStake,
        availableWithdrawal,
        onSubmit,
        onInput,
        onBackToGame
    };
}
