import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { isOptionalString } from '@leon-hub/guards';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { SimpleRegCompletionRouteParametersHandler } from 'web/src/modules/core/services/router/navigation-guards/registration-completion/utils';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import useLoadingError from 'web/src/modules/payments/composables/useLoadingError';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
let PaymentsWithdrawalsDetailsPrefetch = class PaymentsWithdrawalsDetailsPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const withdrawalStore = useWithdrawalStore();
        const paymentSystemData = toRef(withdrawalStore, 'paymentSystemData');
        const isAdditionalRegistrationNeeded = toRef(withdrawalStore, 'isAdditionalRegistrationNeeded');
        const paymentSystemId = to.params.name;
        isOptionalString(paymentSystemId);
        const { showLoadingError } = useLoadingError(router);
        await withdrawalStore.loadWithdrawalSystem(paymentSystemId).catch((error)=>showLoadingError(error));
        "1";
        {
            const customerDataStore = useCustomerDataStore();
            const isExAllowedCountry = toRef(customerDataStore, 'isExAllowedCountry');
            const isRegistrationFinish = toRef(customerDataStore, 'isRegistrationFinish');
            if (isExAllowedCountry.value && !isRegistrationFinish.value) {
                next({
                    name: RouteName.REGISTRATION_COMPLETION,
                    params: {
                        customerAction: CustomerCompletionAction.ExAllowedCountry,
                        paymentSystemId
                    },
                    replace: true
                });
                return;
            }
            if (isAdditionalRegistrationNeeded.value) {
                SimpleRegCompletionRouteParametersHandler.setRedirectLocation(to);
                next({
                    name: RouteName.REGISTRATION_COMPLETION,
                    params: {
                        customerAction: CustomerCompletionAction.Withdrawal,
                        paymentSystemId
                    }
                });
                return;
            }
        }
        if (null === paymentSystemData.value) {
            next({
                name: RouteName.WITHDRAWALS
            });
            return;
        }
        next();
    }
};
export { PaymentsWithdrawalsDetailsPrefetch as default };
