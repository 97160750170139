import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import usePaymentsTopBar from 'web/src/modules/payments/layouts/composables/usePaymentsTopBar';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsTopBarRouteComponent',
    props: {
        paymentType: {},
        page: {},
        showPrefix: {
            type: Boolean,
            default: true
        }
    },
    setup (__props) {
        const props = __props;
        const { title, prefixIcon, onBack, onClose, balanceTitle, headerType, showBalance } = usePaymentsTopBar(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(title),
                "custom-prefix-icon-name": _unref(prefixIcon),
                "only-emit-events": "",
                type: _unref(headerType),
                onBack: _unref(onBack),
                onClose: _unref(onClose)
            }, _createSlots({
                _: 2
            }, [
                void 0
            ]), 1032, [
                "title",
                "custom-prefix-icon-name",
                "type",
                "onBack",
                "onClose"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsTopBarRouteComponent'
                ]
            ]);
        };
    }
});
