import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onBeforeMount, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { Timer } from '@leon-hub/utils';
import PaymentsDepositsOkFailController from 'web/src/modules/payments/components/PaymentsDepositsOkFailController/PaymentsDepositsOkFailController.vue';
import { PaymentsDepositStatus } from 'web/src/modules/payments/enums';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import { useCustomerBalanceUpdateStore } from 'web/src/modules/customer/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsokRoutePage',
    props: {
        margin: {
            type: Boolean
        },
        closeWindow: {
            type: Boolean
        }
    },
    setup (__props) {
        const depositMetricsRequestDelay = toRef(usePaymentsConfig(), 'depositMetricsRequestDelay');
        const { loadData } = useCustomerBalanceUpdateStore();
        onBeforeMount(()=>{
            Timer.setTimeout(loadData, 1000 * depositMetricsRequestDelay.value);
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(PaymentsDepositsOkFailController, {
                title: _ctx.$t('WEB2_PAYMENT_COMPLETED'),
                message: _ctx.$t('WEB2_PAYMENT_COMPLETED_DESCRIPTION'),
                "icon-name": _unref(IconName).CHECK_OUTLINE,
                status: _unref(PaymentsDepositStatus).SUCCESS,
                margin: !!_ctx.margin,
                "close-window": !!_ctx.closeWindow,
                class: _normalizeClass(_ctx.$style['payments-deposits-ok-success'])
            }, null, 8, [
                "title",
                "message",
                "icon-name",
                "status",
                "margin",
                "close-window",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsokRoutePage'
                ]
            ]);
        };
    }
});
