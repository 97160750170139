import { computed, onBeforeMount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { getCryptoNetworkInfo, PaymentType } from '@leon-hub/api-sdk/src/sdk/sdk';
import RouteName from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import { useTheme } from 'web/src/modules/theme/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import getCopyButtonProps from 'web/src/modules/payments/components/PaymentsCrypto/composables/utils/getCopyButtonProps';
import { getPaymentSystemLogoSrc } from 'web/src/modules/payments/utils/getPaymentSystemLogoSrc';
import { useRouterStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import isValidVSelectOptions from 'web/src/components/Select/guards/isValidVSelectOptions';
import { isDropListMenuOptionArray } from 'web/src/components/DropListSelect/guards';
import { getImageQr, getlLstOfMethodsButtonProps, getNetworkOptions } from './utils';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function usePaymentsCrypto(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const { copy } = useCopyToClipboard();
    const gqlClient = useGraphqlClient();
    const analytics = useAnalytics();
    const depositStore = useDepositStore();
    const bonusStore = useBonusStore();
    const formatMoney = useFormatMoney();
    const depositBonusList = toRef(depositStore, 'depositBonusList');
    const selectedBonus = toRef(bonusStore, 'selectedBonus');
    const depositCategories = toRef(depositStore, 'depositCategories');
    const minAmountFormatted = computed(()=>props.paymentSystem?.minAmount ? formatMoney(props.paymentSystem?.minAmount.amount, {
            currency: props.paymentSystem?.minAmount.currency
        }) : null);
    const loading = ref(true);
    const hasError = ref(false);
    const isQRShown = ref(true);
    const cryptoNetwork = ref(null);
    const hintBlockProps = {
        hasIcon: false
    };
    const paymentsLogoProps = getPaymentSystemLogoSrc(props.paymentSystem, useTheme().isDark.value);
    const selectedDepositBonus = computed(()=>depositBonusList.value.find((item)=>item.campaignId === selectedBonus.value));
    async function loadCryptoNetwork(payload) {
        const { paymentSystemId, network, bonusCode } = payload;
        cryptoNetwork.value = await getCryptoNetworkInfo(gqlClient, (node)=>node.queries.finance.deposit.getCryptoNetworkInfo, {
            options: {
                paymentSystemId,
                network: network || null,
                bonusCode
            }
        });
    }
    const currentNetworkName = computed(()=>cryptoNetwork.value?.networks.find((network)=>network.key === cryptoNetwork.value?.current.network)?.value || '');
    const imageQr = computed(()=>getImageQr(cryptoNetwork.value?.current.qrCodeImgSrc));
    const copyButtonProps = computed(()=>getCopyButtonProps($translate, loading.value, hasError.value));
    const hideButtonProps = computed(()=>({
            kind: ButtonKind.SECONDARY_DARK,
            iconName: IconName.QR_CODE,
            label: isQRShown.value ? $translate('WEB2_PAYMENTS_CRYPTO_HIDE_QR').value : $translate('WEB2_PAYMENTS_CRYPTO_SHOW_QR').value,
            fullWidth: true,
            disabled: loading.value || hasError.value
        }));
    const selectedNetwork = computed(()=>cryptoNetwork.value?.current.network);
    const networkSelectOptions = computed(()=>getNetworkOptions(cryptoNetwork.value, isValidVSelectOptions));
    const networkDropdownOptions = computed(()=>getNetworkOptions(cryptoNetwork.value, isDropListMenuOptionArray));
    const cryptoTranslationKeys = computed(()=>({
            cryptoName: cryptoNetwork.value?.current.cryptoName || '',
            networkName: currentNetworkName.value
        }));
    const cryptoCurrencyRateDescription = computed(()=>$translate('WEB_2_CRYPTO_CURRENCY_RATE_DESCRIPTION_WITH_NETWORK', cryptoTranslationKeys).value);
    const cryptoDepositAddressLabel = computed(()=>$translate('WEB_2_CRYPTO_DEPOSIT_ADDRESS', cryptoTranslationKeys).value);
    const listOfMethodsButtonProps = computed(()=>getlLstOfMethodsButtonProps($translate));
    const handleError = (error)=>{
        hasError.value = true;
        throw error;
    };
    const selectPaymentMethod = (id)=>{
        if (id) {
            loading.value = true;
            loadCryptoNetwork({
                paymentSystemId: id,
                bonusCode: selectedDepositBonus.value?.bonusCode || void 0
            }).then(()=>{
                hasError.value = false;
            }).catch((error)=>{
                handleError(error);
            }).finally(()=>{
                loading.value = false;
            });
        }
    };
    const selectNetwork = (network)=>{
        if (props.paymentSystem?.id) {
            loading.value = true;
            loadCryptoNetwork({
                paymentSystemId: props.paymentSystem.id,
                network,
                bonusCode: selectedDepositBonus.value?.bonusCode || void 0
            }).catch((error)=>{
                handleError(error);
            }).finally(()=>{
                loading.value = false;
            });
        }
    };
    const onNativeNetworkSelect = (event)=>{
        selectNetwork(event.target.value);
    };
    const handleCopy = ()=>{
        copy({
            data: cryptoNetwork.value?.current.address,
            notificationText: $translate('WEB_2_CRYPTO_COPY_ADDRESS_SUCCESS').value,
            isProfileModalTooltip: true
        });
    };
    const handleHide = ()=>{
        isQRShown.value = !isQRShown.value;
    };
    const handlePushToMainPaymentPage = ()=>{
        if (props.paymentSystem) {
            const methodName = props.paymentSystem.paymentType === PaymentType.DEPOSIT ? 'depositMethod' : 'withdrawalMethod';
            analytics.clickMap({
                [methodName]: 'chooseAnotherMethod'
            });
        }
        if (useRouterStore().previousRouteName === RouteName.DEPOSITS) router.back();
        else router.replace({
            name: RouteName.DEPOSITS
        });
    };
    if (props.paymentSystem?.id) loadCryptoNetwork({
        paymentSystemId: props.paymentSystem.id,
        bonusCode: selectedDepositBonus.value?.bonusCode || void 0
    }).catch((error)=>{
        handleError(error);
    }).finally(()=>{
        loading.value = false;
    });
    function onDepositBonusChange(bonusCode) {
        if (props.paymentSystem?.id) loadCryptoNetwork({
            paymentSystemId: props.paymentSystem.id,
            network: cryptoNetwork.value?.current?.network,
            bonusCode: bonusCode || selectedDepositBonus.value?.bonusCode || void 0
        });
    }
    onBeforeMount(async ()=>{
        if (!depositCategories.value.length) {
            loading.value = true;
            await depositStore.loadDepositSystems();
            loading.value = false;
        }
    });
    return {
        hasError,
        loading,
        isQRShown,
        hintBlockProps,
        paymentsLogoProps,
        cryptoNetwork,
        listOfMethodsButtonProps,
        imageQr,
        copyButtonProps,
        hideButtonProps,
        selectedNetwork,
        networkSelectOptions,
        networkDropdownOptions,
        cryptoCurrencyRateDescription,
        cryptoDepositAddressLabel,
        depositBonusList,
        minAmountFormatted,
        selectPaymentMethod,
        selectNetwork,
        onNativeNetworkSelect,
        handleCopy,
        handleHide,
        handlePushToMainPaymentPage,
        onDepositBonusChange
    };
}
