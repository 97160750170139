import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCryptoHideButton',
    props: {
        isQRShown: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { $translate } = useI18n();
        const hideButtonProps = computed(()=>({
                label: props.isQRShown ? $translate('WEB2_PAYMENTS_CRYPTO_HIDE_QR').value : $translate('WEB2_PAYMENTS_CRYPTO_SHOW_QR').value,
                fullWidth: true,
                disabled: props.loading || props.hasError
            }));
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LButton, _mergeProps({
                key: 1
            }, hideButtonProps.value, {
                "icon-name": _unref(IconName).QR_CODE,
                kind: _unref(ButtonKind).SECONDARY_DARK,
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click'))
            }), null, 16, [
                "icon-name",
                "kind"
            ]));
    }
});
