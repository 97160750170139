import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { AlertIconName, IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useFormatMoney } from 'web/src/modules/money/composables';
import useBalance from 'web/src/modules/customer/store/composables/useBalance';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsWithdrawalRestrictions',
    props: {
        remainingStake: {},
        availableWithdrawal: {}
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { $translate } = useI18n();
        const { balance } = useBalance();
        const formatMoney = useFormatMoney();
        const analytics = useAnalytics();
        const modalProps = computed(()=>({
                isInnerModal: true,
                isProfileLayout: true,
                ...getImageOrIcon({
                    icon: IconName.INFO,
                    alertIcon: AlertIconName.Withdrawal
                }),
                title: $translate('WEB2_WITHDRAWAL_RESTRICTIONS_TITLE').value,
                buttons: [
                    {
                        action: DialogAction.SECONDARY_CLICK,
                        kind: ButtonKind.PRIMARY,
                        label: $translate('WEB2_WITHDRAWAL_RESTRICTIONS_CONTINUE').value
                    },
                    {
                        action: DialogAction.MODAL_CLOSE,
                        kind: ButtonKind.TRANSPARENT,
                        label: $translate('WEB2_CLOSE').value
                    }
                ]
            }));
        const balanceValue = computed(()=>formatMoney(balance.value));
        function sendAnalytics(eventName) {
            analytics.push(AnalyticsEvent.Z_MODAL_WITHDRAWAL_WAGER_CLICKS, {
                withdrawals: {
                    modalWindowWager: {
                        clicks: eventName
                    }
                }
            });
        }
        function onClose() {
            sendAnalytics('crossIcon');
            emit('close');
        }
        function onButtonClick($event) {
            if ($event.action === DialogAction.MODAL_CLOSE) sendAnalytics('close');
            else if ($event.action === DialogAction.SECONDARY_CLICK) sendAnalytics('backInGame');
            emit('button-click', $event);
        }
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                class: _normalizeClass(_ctx.$style['restriction-modal']),
                modal: modalProps.value,
                onClose: onClose,
                onButtonClick: _cache[1] || (_cache[1] = ($event)=>onButtonClick($event))
            }, {
                default: _withCtx(()=>[
                        _createVNode(_unref(TextSlotPlaceholder), {
                            class: _normalizeClass(_ctx.$style['restriction-modal__label']),
                            text: _ctx.$t('WEB2_WITHDRAWAL_RESTRICTIONS_LABEL')
                        }, {
                            amount: _withCtx(()=>[
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.remainingStake), 1)
                                ]),
                            rules: _withCtx(()=>[
                                    _createVNode(_component_router_link, {
                                        to: {
                                            name: _unref(RouteName).CMS_BETTING_PARTICIPATION_AGREEMENT
                                        },
                                        onClick: _cache[0] || (_cache[0] = ($event)=>sendAnalytics('rules'))
                                    }, {
                                        default: _withCtx(()=>[
                                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_FULL_REG_AGREEDM_RULES')), 1)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "to"
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "class",
                            "text"
                        ]),
                        _createElementVNode("div", null, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['restriction-modal__list'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['restriction-modal__item'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['restriction-modal__title'])
                                    }, _toDisplayString(_ctx.$t('WEB2_WITHDRAWAL_RESTRICTIONS_BALANCE')), 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['restriction-modal__value'])
                                    }, _toDisplayString(balanceValue.value), 3)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['restriction-modal__item'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['restriction-modal__title'])
                                    }, _toDisplayString(_ctx.$t('WEB2_WITHDRAWAL_RESTRICTIONS_AVAILABLE')), 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass({
                                            [_ctx.$style['restriction-modal__value']]: true,
                                            [_ctx.$style['restriction-modal__value--active']]: true
                                        })
                                    }, _toDisplayString(_ctx.availableWithdrawal), 3)
                                ], 2)
                            ], 2)
                        ])
                    ]),
                _: 1
            }, 8, [
                "class",
                "modal"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsWithdrawalRestrictions'
                ]
            ]);
        };
    }
});
