import { computed, onBeforeMount, onBeforeUnmount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { AlertIconName, IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { PaymentType } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useUserStore } from 'web/src/modules/user/store';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import useVerification from 'web/src/modules/profile/submodules/verification/composables/useVerification';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import useAdditionalVerificationModal from 'web/src/modules/payments/submodules/withdrawals/composables/useAdditionalVerificationModal';
import useLoadingError from 'web/src/modules/payments/composables/useLoadingError';
import usePaymentsIdentification from 'web/src/modules/payments/composables/usePaymentsIdentification';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import { ErrorCodeWithdrawal } from 'web/src/modules/payments/enums';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useWithdrawalsRoutePage() {
    const withdrawalStore = useWithdrawalStore();
    const router = useRouter();
    const userStore = useUserStore();
    const paymentConfig = usePaymentsConfig();
    const eventBus = useEventsBus();
    const errorCode = toRef(withdrawalStore, 'errorCode');
    const errorMessage = toRef(withdrawalStore, 'errorMessage');
    const withdrawalCategories = toRef(withdrawalStore, 'withdrawalCategories');
    const isShowFullNotification = ref(false);
    const hasErrorCode = computed(()=>!!errorCode.value && !!errorMessage.value);
    const verification = useVerification();
    const additionalVerification = useAdditionalVerificationModal();
    const { $translate } = useI18n();
    const { showIdentificationStatus, identificationStatusProperties, onIdentificationStatusButtonClick, waitForGettingIdentificationState } = usePaymentsIdentification(PaymentType.WITHDRAWAL);
    const analytics = useAnalytics();
    const { showDialog, closeAllDialogs } = useDialogs();
    const { showLoadingError } = useLoadingError(router);
    const loading = ref(true);
    const titleErrorModal = computed(()=>{
        switch(errorCode.value){
            case ErrorCodeWithdrawal.PAYOUT_BLOCKED:
                return $translate('WEB2_NOTIFICATION_PAYOUT_BLOCKED_TITLE').value;
            case ErrorCodeWithdrawal.VERIFICATION_NEEDED:
                return $translate('WEB2_NOTIFICATION_VERIFICATION_NEEDED_TITLE').value;
            case ErrorCodeWithdrawal.IDENT_NEEDED:
                return $translate('WEB2_NOTIFICATION_IDENT_NEEDED_TITLE').value;
            default:
                return '';
        }
    });
    const routeNameErrorModal = computed(()=>{
        switch(errorCode.value){
            case ErrorCodeWithdrawal.PAYOUT_BLOCKED:
                return RouteName.SUPPORT;
            case ErrorCodeWithdrawal.VERIFICATION_NEEDED:
                return RouteName.CUSTOMER_VERIFICATION;
            case ErrorCodeWithdrawal.IDENT_NEEDED:
                return RouteName.CUSTOMER_VERIFICATION;
            default:
                return '';
        }
    });
    onBeforeMount(async ()=>{
        if (withdrawalCategories.value.length) loading.value = false;
        await Promise.all([
            withdrawalStore.loadWithdrawalSystems().catch((error)=>showLoadingError(error)),
            userStore.loadCustomerData()
        ]);
        await waitForGettingIdentificationState();
        loading.value = false;
        if (errorCode.value) showErrorModal();
        else if (verification.isAccountVerificationRequired.value) additionalVerification.openAdditionVerificationNeededModal();
    });
    onBeforeUnmount(()=>{
        closeAllDialogs();
    });
    function onErrorModalAction() {
        if (!routeNameErrorModal.value) return;
        router.push({
            name: routeNameErrorModal.value
        });
    }
    const errorModalButtonLabel = computed(()=>errorCode.value === ErrorCodeWithdrawal.PAYOUT_BLOCKED ? $translate('WEB2_NOTIFICATIONS_SUPPORT_BTN').value : $translate('WEB2_NOTIFICATION_VERIFICATION_BUTTON').value);
    const hintErrorProperties = computed(()=>({
            icon: IconName.ATTENTION_OUTLINE,
            labelAccept: errorModalButtonLabel.value
        }));
    const hintBlockProperties = computed(()=>({
            icon: IconName.ATTENTION_OUTLINE,
            labelCancel: isShowFullNotification.value ? $translate('WEB2_PAYMENTS_NOTIFICATION_MINIMIZE').value : '',
            labelAccept: isShowFullNotification.value ? $translate('WEB2_MAKE_DEPOSIT').value : $translate('WEB2_PAYMENTS_NOTIFICATION_LEARN_MORE').value
        }));
    function showErrorModal() {
        if (!hasErrorCode.value && !titleErrorModal.value) return;
        showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                isOverlayCloseAllowed: false,
                isCloseAllowed: true,
                title: titleErrorModal.value,
                confirmMessage: errorMessage.value ?? '',
                ...getImageOrIcon({
                    icon: IconName.ATTENTION_OUTLINE,
                    alertIcon: AlertIconName.IdCard
                }),
                buttons: [
                    {
                        label: errorModalButtonLabel.value,
                        action: DialogAction.ADDITIONAL_BUTTON
                    },
                    {
                        label: $translate('JSP_PCL_FBOT_CLOSE').value,
                        action: DialogAction.MODAL_CLOSE
                    }
                ],
                dataTestId: 'withdrawals-error'
            }
        }).subscribe({
            [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                onErrorModalAction();
            }
        });
    }
    function onClickToggle() {
        isShowFullNotification.value = !isShowFullNotification.value;
    }
    function onClickDeposit() {
        router.push({
            name: RouteName.DEPOSITS
        });
    }
    function goToWithdrawalsDetailsRoute(system) {
        if (errorCode.value) {
            showErrorModal();
            return;
        }
        if (system.id && !system.disabled) router.push({
            name: RouteName.WITHDRAWALS_DETAILS,
            params: {
                name: system.id
            }
        });
    }
    function goToDeposits() {
        analytics.clickMap({
            deposit: 'withdrawalListDepositLink'
        });
        router.push({
            name: RouteName.DEPOSITS
        });
        eventBus.emit(BusEvent.MODAL_CONTENT_SCROLL_TOP, {});
    }
    return {
        loading,
        showIdentificationStatus,
        identificationStatusProperties,
        onIdentificationStatusButtonClick,
        hasErrorCode,
        hintErrorProperties,
        onErrorModalAction,
        titleErrorModal,
        errorMessage,
        isNoMethodMessageEnabled: paymentConfig.isNoMethodMessageEnabled,
        withdrawalCategories,
        hintBlockProperties,
        isShowFullNotification,
        onClickToggle,
        onClickDeposit,
        goToWithdrawalsDetailsRoute,
        goToDeposits
    };
}
