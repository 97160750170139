import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import PaymentsDepositsOkFailController from 'web/src/modules/payments/components/PaymentsDepositsOkFailController/PaymentsDepositsOkFailController.vue';
import { usePaymentsWithdrawalsokRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsWithdrawalsokRoutePage',
    props: {
        closeWindow: {}
    },
    setup (__props) {
        const props = __props;
        const { margin, showDefaultDialog, closeWindow } = usePaymentsWithdrawalsokRoutePage(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['payments-withdrawals-ok-success-wrapper'])
            }, [
                _unref(showDefaultDialog) ? (_openBlock(), _createBlock(PaymentsDepositsOkFailController, {
                    key: 0,
                    title: _ctx.$t('WEB2_WITHDRAWALOK_TITLE'),
                    message: _ctx.$t('WEB2_WITHDRAWALOK_TEXT'),
                    "icon-name": _unref(IconName).CHECK_OUTLINE,
                    margin: _unref(margin),
                    "close-window": _unref(closeWindow),
                    class: _normalizeClass(_ctx.$style['payments-withdrawals-ok-success'])
                }, null, 8, [
                    "title",
                    "message",
                    "icon-name",
                    "margin",
                    "close-window",
                    "class"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsWithdrawalsokRoutePage'
                ]
            ]);
        };
    }
});
